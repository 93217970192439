import { IsEnum, IsNotEmpty, IsBoolean, IsOptional, IsString } from 'class-validator';
import { PayrollCycleStep } from '../enums';

export class ClientPayrollStepRequestDto {
  @IsEnum(PayrollCycleStep)
  type: PayrollCycleStep;

  @IsNotEmpty()
  @IsBoolean()
  isCompleted: boolean;

  @IsNotEmpty()
  dueDate: string;

  @IsOptional()
  @IsString()
  file?: string;
}
