export enum EmployeeWorkExpenseType {
  // Flight expenses
  FLIGHT = 'flight',
  // Hotel expenses
  HOTEL = 'hotel',
  // Fuel expenses
  FUEL = 'fuel',
  // Mobile phone expenses
  MOBILE_PHONE = 'mobile-phone',
  // Client entertainment expenses
  CLIENT_ENTERTAINMENT = 'client-entertainment',
  // F&B expenses
  F_B = 'f-b',
  // Training expenses
  TRAINING = 'training',
  // Other expenses
  OTHER = 'other',
}
