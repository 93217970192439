/**
 * See https://app.clickup.com/24448268/v/dc/qa38c-2381/qa38c-1261 for complete list
 */
export enum EmployeeDocumentType {
  // Passport Copy
  PASSPORT_COPY = 'passport-copy',
  // Passport Photo
  PASSPORT_PHOTO = 'passport-photo',
  // Education Certificate Attested
  EDUCATION_CERTIFICATE_ATTESTED = 'education-certificate-attested',
  // E VISA
  E_VISA = 'e-visa',
  // Residence Visa (inc. Dependents)
  RESIDENCE_VISA = 'residence-visa',
  // Work permit
  WORK_PERMIT = 'work-permit',
  // Emirates ID
  EMIRATES_ID = 'emirate-id',
  // Offer Letter
  OFFER_LETTER = 'offer-letter',
  // Medical Insurance (inc. Dependents)
  MEDICAL_INSURANCE = 'medical-insurance',
  // Employment Contract
  EMPLOYMENT_CONTRACT = 'employment-contract',

  // Iqama Copy (inc. Dependents)
  IQAMA_COPY = 'iqama_copy',
  // Ajeer Certificate
  AJEER_CERTIFICATE = 'ajeer-certificate',
  // Exit and Re-entry Permit
  EXIT_AND_RE_ENTRY_PERMIT = 'exit-and-re-entry-permit',

  // QID (inc. Dependents)
  QID = 'qid',
  // Hamad Medical Card
  HAMAD_MEDICAL_CARD = 'hamad-medical-card',

  // Civil ID (inc. Dependents)
  CIVIL_ID = 'civil-id',

  // NOC 1 (PAM)
  NOC1_PAM = 'noc1-pam',
  // NOC 2 (MOI)
  NOC2_MOI = 'noc2-moi',
}
