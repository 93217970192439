import { notify } from "@kyvg/vue3-notification";
import { AxiosError, AxiosResponse } from "axios";

import axiosReq from "@/utils/axiosReq";

class ClientPayrollCycleApiService {
  constructor() {}

  public getPayrollCycle(uuid: string, date: string): AxiosResponse {
    return axiosReq({
      url: `/clients/${uuid}/payroll-cycle-steps/${date}`,
      method: 'get',
    }).catch((err: AxiosError) => {
      notify({
        title: "Error load payroll cycle",
        text: err.message,
        type: "error",
      });
    }) 
  }
}

export default new ClientPayrollCycleApiService();
