import { Commit } from "vuex";
export type State = {
    employees: Array<{
        id: string,
        date: string,
        name: string,
        address: string
    }>;
};
const state: State = {
    employees: []
};

const getters = {
    getEmployeeById: (state: State) => (id: string) => state?.employees.find(employee => employee.id === id),
    stateEmployees: (state: State) => state.employees,
};

const actions = {
    async Register({ dispatch }: any, data: any) {
        await dispatch("LogIn", data);
    },

    async LogIn({ commit }: { commit: Commit }, user: any) {
        await commit("setUser", user);
    },

    async LogOut({ commit }: { commit: Commit }) {
        let user = null;
        commit("logout", user);
    },
};

const mutations = {
    setEmployees(state: State, employees: any) {
        state.employees = employees;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
