<template>
  <div class="login-container">
    <SuccessPage
      :title="$t('registration.registration-sent-confirmation-email-employee')"
      :description="
        $t(
          'registration.registration-sent-confirmation-email-description-employee'
        )
      "
      :image="flower"
      :backgroundImage="vector2"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import SuccessPage from "../../../components/SuccessPage/SuccessPage.vue";

import flower from "../../../assets/Flower.svg";
import vector2 from "../../../assets/Vector2.svg";

export default defineComponent({
  name: "SignUp",
  components: {
    SuccessPage,
  },

  setup() {
    return {
      flower,
      vector2,
    };
  },
});
</script>

<style lang="scss" scoped>
.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}
</style>
