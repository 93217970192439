import { event, set } from "vue-gtag";

class GoogleAnalyticsService {
  constructor() {}

  setProperties(user_uuid: string, user_role: string, email: string) {
    return set({
      user_properties: {
        user_uuid,
        user_role,
        email,
      },
    });
  }

  trackEvent(eventType: string, data?: any) {
    return event(eventType, data);
  }
}

export default new GoogleAnalyticsService();
