<template>
  <svg :class="className" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Iconly/Light-Outline/Delete">
      <g id="Delete">
        <path id="Fill 1" fill-rule="evenodd" clip-rule="evenodd" d="M10.2055 18.3336C9.07629 18.3336 7.97546 18.3211 6.88629 18.2986C5.49296 18.2711 4.52879 17.3678 4.37129 15.9411C4.10879 13.5745 3.65962 7.99612 3.65546 7.94029C3.62712 7.59612 3.88379 7.29445 4.22796 7.26695C4.56712 7.25779 4.87379 7.49612 4.90129 7.83945C4.90546 7.89612 5.35379 13.4553 5.61379 15.8036C5.70296 16.6145 6.14046 17.0328 6.91212 17.0486C8.99546 17.0928 11.1213 17.0953 13.413 17.0536C14.233 17.0378 14.6763 16.6278 14.768 15.7978C15.0263 13.4695 15.4763 7.89612 15.4813 7.83945C15.5088 7.49612 15.813 7.25612 16.1538 7.26695C16.498 7.29529 16.7546 7.59612 16.7271 7.94029C16.7221 7.99695 16.2705 13.5895 16.0105 15.9353C15.8488 17.3911 14.8871 18.277 13.4355 18.3036C12.3246 18.3228 11.253 18.3336 10.2055 18.3336Z" fill="#FF7C6D"/>
        <path id="Fill 3" fill-rule="evenodd" clip-rule="evenodd" d="M17.2567 5.82471H3.125C2.78 5.82471 2.5 5.54471 2.5 5.19971C2.5 4.85471 2.78 4.57471 3.125 4.57471H17.2567C17.6017 4.57471 17.8817 4.85471 17.8817 5.19971C17.8817 5.54471 17.6017 5.82471 17.2567 5.82471Z" fill="#FF7C6D"/>
        <path id="Fill 5" fill-rule="evenodd" clip-rule="evenodd" d="M14.5335 5.82449C13.5852 5.82449 12.7618 5.14866 12.5752 4.21866L12.3727 3.20533C12.3302 3.05116 12.1543 2.91699 11.9543 2.91699H8.42682C8.22682 2.91699 8.05099 3.05116 8.00016 3.24366L7.80599 4.21866C7.62016 5.14866 6.79599 5.82449 5.84766 5.82449C5.50266 5.82449 5.22266 5.54449 5.22266 5.19949C5.22266 4.85449 5.50266 4.57449 5.84766 4.57449C6.20266 4.57449 6.51099 4.32116 6.58099 3.97283L6.78349 2.95949C6.98932 2.18283 7.66182 1.66699 8.42682 1.66699H11.9543C12.7193 1.66699 13.3918 2.18283 13.5893 2.92199L13.801 3.97283C13.8702 4.32116 14.1785 4.57449 14.5335 4.57449C14.8785 4.57449 15.1585 4.85449 15.1585 5.19949C15.1585 5.54449 14.8785 5.82449 14.5335 5.82449Z" fill="#FF7C6D"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "RemoveSVGComponent",
  props: {
    color: {
      type: String,
      required: false,
    },
    className: {
      type: String,
      required: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.disabled {
  path {
    fill: #666F88;
    opacity: 0.4;
  }
}
</style>