import { BaseResponseDto } from '../../../core';
import { EmployeeOvertimeRequestStatus, EmployeeOvertimeRequestType } from '../../enums';
import { UserDto } from '../../../user';

export class EmployeeOvertimeRequestEmployeeResponseDto extends BaseResponseDto {
  type: EmployeeOvertimeRequestType;
  date: Date;
  amount: number;
  comment: string;
  status: EmployeeOvertimeRequestStatus;
  approvedAt?: Date;
  rejectedAt?: Date;
  clientComment?: string;
  resolvedBy?: UserDto;
}
