import { IsEnum, IsString, MaxLength, IsDateString } from 'class-validator';
import { EmployeeLeaveRequestType } from '../enums';

export class EmployeeLeaveRequestCreateRequestDto {
  @IsEnum(EmployeeLeaveRequestType)
  type: EmployeeLeaveRequestType;

  @IsDateString()
  from: Date;

  @IsDateString()
  to: Date;

  certificate?: string;

  @IsString()
  @MaxLength(500)
  comment: string;
}
