import { IsEmail, IsNotEmpty, IsPhoneNumber, IsString, MinLength, IsOptional, Matches } from 'class-validator';
import { IsPasswordRepeat } from './validation-decorator';
import { UpdateClientRequestDto } from './update-client-request.dto';
import { IsDateString } from 'class-validator';

export class AddClientRequestDto extends UpdateClientRequestDto {
  @IsNotEmpty({ message: 'Company Name should not be empty' })
  companyName: string;

  @IsString()
  @IsNotEmpty({ message: 'Full Name should not be empty' })
  fullName: string;

  @MinLength(8, { message: 'Should be at least 8 characters long' })
  @Matches(/[!@#$%^&*]+/, { message: 'Should contain one of the following characters !@#$%^&*' })
  @Matches(/[a-z]+/, { message: 'Should contain at least one lower case character' })
  @Matches(/[A-Z]+/, { message: 'Should contain at least one uppercase letter.' })
  @Matches(/[0-9]+/, { message: 'Should contain at least one number' })
  @IsNotEmpty({ message: 'Password should not be empty' })
  declare password: string;

  @IsNotEmpty({ message: 'Password should not be empty' })
  @IsPasswordRepeat({
    message: 'Password and Repeat Password are not equal',
  })
  repeatPassword: string;

  @IsEmail(undefined, { message: 'Email should be a valid email address' })
  email: string;

  @IsPhoneNumber(undefined, { message: 'Phone should be a valid phone number' })
  @IsOptional()
  phone: string;

  @IsDateString()
  @IsOptional()
  declare startDate?: Date;
}
