export enum EmployeeDocumentRequestType {
  // Salary certificate
  SALARY_CERTIFICATE = 'salary-certificate',
  // Salary transfer letter
  SALARY_TRANSFER_LETTER = 'salary-transfer_letter',
  // Employment Certificate
  EMPLOYMENT_CERTIFICATE = 'employment-certificate',
  // Experience Certificate
  EXPERIENCE_CERTIFICATE = 'experience-certificate',
  // NOC 1 (Obtain Visit Visa/Entry Permit)
  NOC1 = 'noc_1',
  // NOC 2 (Obtain Driving License)
  NOC2 = 'noc_2',
  // NOC 3 (Obtain Alcohol License)
  NOC3 = 'noc_3',
  // NOC 4 (Other)
  NOC4 = 'noc_4',
}
