import { capitalizeFirstLetter, formatDate } from "@/utils/data";
import {
  EmployeeOvertimeRequestEmployeeResponseDto,
  EmployeeOvertimeRequestStatus,
} from "@/dto/src";

import i18n from "@/i18n";

class EmployeeOvertimeFormatService {
  constructor() {}

  public getEmployeeOvertimesInTableFormat(
    overtimes: EmployeeOvertimeRequestEmployeeResponseDto[]
  ) {
    const columnNames = [
      {
        name: i18n.global.t("elements.elements-requested-on-text"),
        width: "228",
      },
      {
        name: i18n.global.t("elements.elements-overtime-type-text"),
        width: "228",
      },
      {
        name: i18n.global.t("elements.elements-overtime-date-text"),
        width: "228",
      },
      {
        name: i18n.global.t("elements.elements-hours-text"),
        width: "228",
      },
      {
        name: i18n.global.t("elements.elements-comment-text"),
        width: "70",
      },
      {
        name: i18n.global.t("elements.elements-status-text"),
        width: "228",
      },
      {
        name: i18n.global.t("elements.elements-actions-text"),
        width: "162",
      },
    ];

    const fieldsNames = [
      "updatedAt",
      "type",
      "forematedDate",
      "amount",
      "comment",
      "status",
      "action",
    ];
    const data = overtimes.map((overtime) => {
      return {
        id: overtime.uuid,
        updatedAt: formatDate(overtime.updatedAt),
        type: overtime?.type?.toUpperCase() || "",
        forematedDate: formatDate(overtime.date || ""),
        date: overtime.date || "",
        amount: overtime.amount || "",
        comment: {
          type: "customComponent",
          component: "comment",
          text: overtime.comment ? overtime.comment : "",
        },
        status: {
          type: "customComponent",
          component: "statusWithComment",
          statusType: overtime.status,
          statusText: capitalizeFirstLetter(overtime.status),
          text: overtime.clientComment || "",
        },
        action: {
          type: "customComponent",
          component: "approveAction",
          actionTypes: ["edit", "remove"],
          isDisabled: overtime.status !== EmployeeOvertimeRequestStatus.PENDING,
        },
      };
    });
    return {
      columnNames,
      fieldsNames,
      tableData: data,
    };
  }
}

export default new EmployeeOvertimeFormatService();
