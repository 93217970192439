import {
  IsEmail,
  IsEnum,
  IsInt,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsPhoneNumber,
  IsString,
  Max,
  Min,
} from 'class-validator';
import { EmployeeGenders, EmployeeMaritalStatus, EmployeeStatus, EmploymentType, EmploymentVisaStatus } from './enums';

export class EmployeeDto {
  @IsString()
  @IsNotEmpty()
  fullName: string;

  @IsEmail()
  email: string;

  @IsPhoneNumber()
  @IsOptional()
  phone?: string;

  @IsString()
  @IsNotEmpty()
  position: string;

  @IsNotEmpty()
  startDate: Date;

  @IsOptional()
  @IsNumber()
  @Min(0)
  @Max(60)
  @IsInt()
  contractTenure?: number;

  @IsEnum(EmploymentType)
  employmentType: EmploymentType;

  @IsOptional()
  designation?: string;

  @IsOptional()
  gender?: EmployeeGenders;

  @IsOptional()
  nationality?: string;

  @IsOptional()
  @IsEnum(EmployeeMaritalStatus)
  maritalStatus?: EmployeeMaritalStatus;

  @IsOptional()
  sponsorName?: string;

  @IsOptional()
  @IsEnum(EmploymentVisaStatus)
  visaStatus?: EmploymentVisaStatus;

  @IsOptional()
  @IsNumber()
  @Min(1)
  @Max(7)
  workingDays?: number;

  @IsOptional()
  weekendDays?: number[];

  @IsOptional()
  @IsEnum(EmployeeStatus)
  status?: EmployeeStatus;

  @IsOptional()
  onboardingType?: string;

  @IsOptional()
  onboardingNotes?: string;

  @IsOptional()
  lastWorkingDay?: Date;
}
