import { createI18n } from "vue-i18n";
import ru from "./ru";
import en from "./en";

const messages = {
  en,
  ru,
};

const i18n = createI18n({
  locale: "en", // set locale
  fallbackLocale: "ru", // set fallback locale
  messages, // set locale messages
});

export default i18n;
