<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask id="path-1-inside-1_2639_45423" fill="white">
      <path
        d="M18 10C18 8.74125 17.703 7.50026 17.1331 6.37792C16.5631 5.25558 15.7364 4.28356 14.7201 3.54087L13.3179 5.45972C14.0323 5.98177 14.6134 6.66503 15.014 7.45395C15.4146 8.24287 15.6234 9.11519 15.6234 10H18Z"
      />
    </mask>
    <path
      d="M18 10C18 8.74125 17.703 7.50026 17.1331 6.37792C16.5631 5.25558 15.7364 4.28356 14.7201 3.54087L13.3179 5.45972C14.0323 5.98177 14.6134 6.66503 15.014 7.45395C15.4146 8.24287 15.6234 9.11519 15.6234 10H18Z"
      :stroke="color || '#0A1E46'"
      stroke-width="2.7"
      stroke-linejoin="bevel"
      mask="url(#path-1-inside-1_2639_45423)"
    />
    <path d="M16.8 12.4L19.6 10H14L16.8 12.4Z" :fill="color || '#0A1E46'" />
    <g opacity="0.3">
      <mask id="path-3-inside-2_2639_45423" fill="white">
        <path
          d="M10 18C11.2588 18 12.4997 17.703 13.6221 17.1331C14.7444 16.5631 15.7164 15.7364 16.4591 14.7201L14.5403 13.3179C14.0182 14.0323 13.335 14.6134 12.5461 15.014C11.7571 15.4146 10.8848 15.6234 10 15.6234L10 18Z"
        />
      </mask>
      <path
        d="M10 18C11.2588 18 12.4997 17.703 13.6221 17.1331C14.7444 16.5631 15.7164 15.7364 16.4591 14.7201L14.5403 13.3179C14.0182 14.0323 13.335 14.6134 12.5461 15.014C11.7571 15.4146 10.8848 15.6234 10 15.6234L10 18Z"
        :stroke="color || '#0A1E46'"
        stroke-width="2.7"
        stroke-linejoin="bevel"
        mask="url(#path-3-inside-2_2639_45423)"
      />
      <path d="M7.6 16.8L10 19.6L10 14L7.6 16.8Z" :fill="color || '#0A1E46'" />
    </g>
    <mask id="path-5-inside-3_2639_45423" fill="white">
      <path
        d="M2 10C2 11.2587 2.29703 12.4997 2.86694 13.6221C3.43685 14.7444 4.26356 15.7164 5.27987 16.4591L6.6821 14.5403C5.96771 14.0182 5.3866 13.335 4.98599 12.5461C4.58539 11.7571 4.3766 10.8848 4.3766 10L2 10Z"
      />
    </mask>
    <path
      d="M2 10C2 11.2587 2.29703 12.4997 2.86694 13.6221C3.43685 14.7444 4.26356 15.7164 5.27987 16.4591L6.6821 14.5403C5.96771 14.0182 5.3866 13.335 4.98599 12.5461C4.58539 11.7571 4.3766 10.8848 4.3766 10L2 10Z"
      :stroke="color || '#0A1E46'"
      stroke-width="2.7"
      stroke-linejoin="bevel"
      mask="url(#path-5-inside-3_2639_45423)"
    />
    <path d="M3.2 7.6L0.400002 10L6 10L3.2 7.6Z" :fill="color || '#0A1E46'" />
    <g opacity="0.3">
      <mask id="path-7-inside-4_2639_45423" fill="white">
        <path
          d="M10 2C8.74125 2 7.50026 2.29703 6.37792 2.86694C5.25558 3.43685 4.28356 4.26356 3.54087 5.27987L5.45972 6.6821C5.98177 5.96771 6.66503 5.3866 7.45395 4.98599C8.24287 4.58539 9.11519 4.3766 10 4.3766L10 2Z"
        />
      </mask>
      <path
        d="M10 2C8.74125 2 7.50026 2.29703 6.37792 2.86694C5.25558 3.43685 4.28356 4.26356 3.54087 5.27987L5.45972 6.6821C5.98177 5.96771 6.66503 5.3866 7.45395 4.98599C8.24287 4.58539 9.11519 4.3766 10 4.3766L10 2Z"
        :stroke="color || '#0A1E46'"
        stroke-width="2.7"
        stroke-linejoin="bevel"
        mask="url(#path-7-inside-4_2639_45423)"
      />
      <path
        d="M12.4 3.2L10 0.400002L10 6L12.4 3.2Z"
        :fill="color || '#0A1E46'"
      />
    </g>
    <circle
      opacity="0.3"
      cx="9.99992"
      cy="9.99992"
      r="2.66667"
      :fill="color || '#0A1E46'"
    />
  </svg>
</template>

<script>
export default {
  name: "PayrollSVGComponent",
  props: {
    color: {
      type: String,
      required: false,
    },
  },
};
</script>
