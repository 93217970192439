import { IsNotEmpty, IsOptional, IsString } from 'class-validator';
import { EmployeeDto } from './employee.dto';

export class EmployeeRequestDto extends EmployeeDto {
  @IsNotEmpty()
  @IsString()
  countryUuid: string;

  @IsNotEmpty()
  @IsString()
  clientUuid?: string;
}
