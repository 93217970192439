<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.57266 3.51172C5.91466 3.51172 4.53966 4.85372 4.50066 6.50872V17.3397C4.47166 18.9867 5.77866 20.3387 7.41366 20.3667L7.56066 20.3657H15.5727C17.2147 20.3477 18.5537 18.9907 18.5517 17.3407V8.34172L13.9177 3.51172H7.58466H7.57266ZM7.39966 21.8667C4.92666 21.8237 2.95866 19.7867 3.00066 17.3267V6.49072C3.05766 4.00972 5.10666 2.01172 7.56966 2.01172H7.58766H14.2367C14.4407 2.01172 14.6357 2.09472 14.7777 2.24172L19.8437 7.52072C19.9767 7.65972 20.0517 7.84672 20.0517 8.03972V17.3397C20.0557 19.8087 18.0497 21.8397 15.5807 21.8657L7.39966 21.8667Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.2971 8.98449H16.5431C14.7121 8.97949 13.2241 7.48749 13.2241 5.65949V2.75049C13.2241 2.33649 13.5601 2.00049 13.9741 2.00049C14.3881 2.00049 14.7241 2.33649 14.7241 2.75049V5.65949C14.7241 6.66349 15.5411 7.48149 16.5451 7.48449H19.2971C19.7111 7.48449 20.0471 7.82049 20.0471 8.23449C20.0471 8.64849 19.7111 8.98449 19.2971 8.98449Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7935 13.6641H8.89453C8.48053 13.6641 8.14453 13.3281 8.14453 12.9141C8.14453 12.5001 8.48053 12.1641 8.89453 12.1641H13.7935C14.2075 12.1641 14.5435 12.5001 14.5435 12.9141C14.5435 13.3281 14.2075 13.6641 13.7935 13.6641Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.3442 16.1144C10.9302 16.1144 10.5942 15.7784 10.5942 15.3644V10.4644C10.5942 10.0504 10.9302 9.71436 11.3442 9.71436C11.7582 9.71436 12.0942 10.0504 12.0942 10.4644V15.3644C12.0942 15.7784 11.7582 16.1144 11.3442 16.1144Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: "DocumentPlusSVGComponent",
  props: {
    color: {
      type: String,
      required: false,
    },
    className: {
      type: String,
      required: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.disabled {
  path {
    fill: #666F88;
    opacity: 0.6;
  }
}
</style>