import { registerDecorator, ValidationOptions, ValidationArguments } from 'class-validator';

interface IsPasswordRepeatOptions extends ValidationOptions {
  passwordPropertyName?: string;
}

export function IsPasswordRepeat(validationOptions: IsPasswordRepeatOptions) {
  return function (object: any, propertyName: string) {
    registerDecorator({
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      constraints: [],
      validator: {
        validate(value: any, args: ValidationArguments) {
          const passPropertyName = validationOptions.passwordPropertyName || 'password';

          return value === args.object[passPropertyName as keyof object];
        },
      },
    });
  };
}
