import { IsEnum, IsNumber, IsOptional, Min } from 'class-validator';
import {
  EmployeeAirfareTypeEnum,
  EmployeeGratuityLeaveType,
  EmployeeSecurityType,
  EmploymentAirfareEntitlement,
} from './enums';

export class EmployeeOtherDto {
  @IsOptional()
  @IsEnum(EmployeeGratuityLeaveType)
  gratuityType?: EmployeeGratuityLeaveType;

  @IsNumber()
  @IsOptional()
  @Min(0)
  monthlyGratuityCost?: number;

  @IsOptional()
  @IsEnum(EmployeeAirfareTypeEnum)
  airfareType?: EmployeeAirfareTypeEnum;

  @IsNumber()
  @IsOptional()
  @Min(0)
  airfareAllowance?: number;

  @IsNumber()
  @IsOptional()
  @Min(0)
  supplierAdminFee?: number;

  @IsNumber()
  @IsOptional()
  @Min(0)
  recruitmentPartnerFee?: number;

  @IsNumber()
  @IsOptional()
  @Min(0)
  auxiliumFee?: number;

  @IsOptional()
  invoicingCurrencyUuid?: string;

  @IsOptional()
  @IsEnum(EmployeeSecurityType)
  clientSecurityType?: EmployeeSecurityType;

  @IsOptional()
  @IsEnum(EmploymentAirfareEntitlement)
  airfareEntitlement?: EmploymentAirfareEntitlement;

  @IsOptional()
  securityCurrencyUuid?: string;

  @IsOptional()
  @IsNumber()
  @Min(0)
  securityValue?: number;

  @IsOptional()
  securityComment?: string;
}
