import Translation from "../types";

import registration from "./registration.json";
import employee from "./employee.json";
import elements from "./elements.json";
import validations from "./validations.json";
import client from "./client.json";

const translation: Translation = {
  elements,
  registration,
  employee,
  validations,
  client,
};
export default translation;
