<template>
  <svg
    width="30"
    height="34"
    viewBox="0 0 30 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      d="M26.3484 12.0352C25.5959 12.0352 24.5992 12.0185 23.3583 12.0185C20.3318 12.0185 17.8434 9.5135 17.8434 6.4585V1.0985C17.8434 0.676829 17.5051 0.333496 17.0876 0.333496H8.27244C4.16023 0.333496 0.833496 3.71016 0.833496 7.8485V25.8068C0.833496 30.1485 4.31865 33.6668 8.61733 33.6668H21.7427C25.8434 33.6668 29.1668 30.3118 29.1668 26.1702V12.7852C29.1668 12.3618 28.8302 12.0202 28.4111 12.0218C27.7064 12.0268 26.8616 12.0352 26.3484 12.0352Z"
      fill="white"
    />
    <path
      opacity="0.4"
      d="M21.8068 1.27895C21.3101 0.760616 20.4385 1.11728 20.4385 1.83562V6.23062C20.4385 8.07395 21.9568 9.59062 23.8001 9.59062C24.9618 9.60395 26.5751 9.60728 27.9451 9.60395C28.6468 9.60228 29.0035 8.76395 28.5168 8.25728C26.7585 6.42895 23.6101 3.15228 21.8068 1.27895Z"
      fill="white"
    />
    <path
      d="M16.5584 18.6425L18.5934 16.6059C19.0784 16.1225 19.0784 15.3375 18.5934 14.8525C18.1084 14.3675 17.3234 14.3675 16.8384 14.8525L14.8034 16.8859L12.7684 14.8525C12.2834 14.3675 11.4967 14.3675 11.0117 14.8525C10.5267 15.3375 10.5267 16.1225 11.0117 16.6059L13.0484 18.6425L11.0117 20.6775C10.5267 21.1625 10.5267 21.9475 11.0117 22.4309C11.255 22.6742 11.5717 22.7959 11.89 22.7959C12.2084 22.7959 12.525 22.6742 12.7684 22.4309L14.8034 20.3975L16.8384 22.4309C17.08 22.6742 17.3984 22.7959 17.7167 22.7959C18.0334 22.7959 18.3517 22.6742 18.5934 22.4309C19.0784 21.9475 19.0784 21.1625 18.5934 20.6775L16.5584 18.6425Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "PaperFailSVGComponent",
  props: {
    color: {
      type: String,
      required: false,
    },
  },
};
</script>
