<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" rx="4" fill="#CACBD5" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.4118 12.076C20.4118 14.5235 18.4494 16.486 16.0002 16.486C13.5517 16.486 11.5885 14.5235 11.5885 12.076C11.5885 9.62843 13.5517 7.66675 16.0002 7.66675C18.4494 7.66675 20.4118 9.62843 20.4118 12.076ZM16.0002 24.3334C12.3855 24.3334 9.3335 23.7459 9.3335 21.4792C9.3335 19.2117 12.4046 18.645 16.0002 18.645C19.6157 18.645 22.6668 19.2325 22.6668 21.4992C22.6668 23.7667 19.5957 24.3334 16.0002 24.3334Z"
      fill="#666F88"
    />
  </svg>
</template>

<script>
export default {
  name: "DefaultUserSVGComponent",
  props: {
    color: {
      type: String,
      required: false,
    },
  },
};
</script>
