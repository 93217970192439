import store from "@/store";
import { Auth0ClientState, AuthState } from "@/store/modules/auth";
import axiosReq from "@/utils/axiosReq";

import { AddClientRequestDto } from "@/dto/src";
import { notify } from "@kyvg/vue3-notification";
import { AxiosError, AxiosResponse } from "axios";

class ClientService {
  constructor() {}

  getRoles(authUser: Auth0ClientState): string[] {
    return store.getters.stateAuthUser["http://www.auxillium.com/roles"];
  }

  isAdmin(authUser: Auth0ClientState) {
    const roles = this.getRoles(authUser);
    if (!roles) {
      return false;
    }
    return roles.includes("Admin");
  }

  isEmployee(authUser: Auth0ClientState) {
    const roles = this.getRoles(authUser);
    if (!roles) {
      return false;
    }
    return roles.includes("Employee");
  }

  public getAllClientManagers(
    limit: number = 15,
    page: number = 1,
    searchValue: string = ""
  ): AxiosResponse {
    return axiosReq({
      url: `/client-managers?limit=${limit}&page=${page}&search=${searchValue}`,
      method: "get",
    });
  }

  public createClientManagers(data: {
    fullName: string;
    email: string;
  }): AxiosResponse {
    return axiosReq({
      url: `/client-managers`,
      method: "post",
      data,
    });
  }

  public editClientManagers(
    uuid: string,
    data: {
      fullName: string;
      email: string;
    }
  ): AxiosResponse {
    return axiosReq({
      url: `/client-managers/${uuid}`,
      method: "put",
      data,
    });
  }

  public deleteClientManagers(uuid: string): AxiosResponse {
    return axiosReq({
      url: `/client-managers/${uuid}`,
      method: "delete",
    });
  }

  loadCurrentRole() {
    return axiosReq({
      url: "/users/me/role",
      method: "get",
    }).catch((err: AxiosError) => {
      console.log("err", err);
    });
  }

  loadClientInfo() {
    return axiosReq({
      url: "/clients/users/me",
      method: "get",
    }).catch((err: AxiosError) => {
      console.log("err", err);
    });
  }

  loadEmployeeInfo() {
    return axiosReq({
      url: "/users/me",
      method: "get",
    }).catch((err: AxiosError) => {
      console.log("err", err);
    });
  }

  adminLogout(id: string) {
    return axiosReq({
      url: "/logout",
      method: "get",
    }).catch((err: AxiosError) => {
      notify({
        title: "Error load client info",
        text: err.message,
        type: "error",
      });
    });
  }

  resetPassword() {
    return axiosReq({
      url: "/users/reset-password",
      method: "get",
    }).catch((err: AxiosError) => {
      notify({
        title: "Error reset password",
        text: err.message,
        type: "error",
      });
    });
  }

  createClient(data: AddClientRequestDto) {
    return axiosReq({
      url: "/clients",
      method: "post",
      data,
    }).catch((err: AxiosError) => {
      if (err.response?.status === 409) {
        notify({
          title: "Error create client",
          text: err.response.data.message,
          type: "error",
        });
        return;
      }
      Object.values(
        (
          err.response as {
            data: {
              message: [];
            };
          }
        ).data.message
      )
        .flat()
        .forEach((text: string) => {
          notify({
            title: "Error create client",
            text,
            type: "error",
          });
        });
    });
  }
}

export default new ClientService();
