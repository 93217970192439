import {
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw,
} from "vue-router";
import Employees from "@/views/client/Employees/AllEmployees.vue";
import EmployeesPage from "@/views/client/Employees/EmployeesPage.vue";
import UserSettings from "@/views/client/Client/UserSettings.vue";
import EmptyPage from "@/views/client/EmptyPage/EmptyPage.vue";
import Reports from "@/views/client/Reports/Reports.vue";
import Documents from "@/views/client/Documents/Documents.vue";
import Expense from "@/views/client/Expense/Expense.vue";
import ClientManagers from "@/views/client/ClientManagers/ClientManagers.vue";
import NotificationsView from "@/views/shared/NotificationsView/NotificationsView.vue";
import Leaves from "@/views/client/Leaves/Leaves.vue";
import Overtimes from "@/views/client/Overtimes/Overtimes.vue";
import PayrollCycle from "@/views/client/PayrollCycle/PayrollCycle.vue";
import store from "../store";

export const clientRoutes: Array<RouteRecordRaw> = [
  {
    path: "/employees",
    name: "Employees",
    component: Employees,
    meta: { requiresClient: true, requiresClientManager: true },
  },
  {
    path: "/employees/:userId",
    name: "EmployeesPage",
    component: EmployeesPage,
    meta: { requiresClient: true, requiresClientManager: true },
  },
  {
    path: "/settings",
    name: "SettingsPage",
    component: UserSettings,
    meta: { requiresClient: true, requiresClientManager: true },
  },
  {
    path: "/payroll-cycle",
    name: "Payroll-cycle",
    component: PayrollCycle,
    meta: { requiresClient: true, requiresClientManager: true },
  },
  {
    path: "/adjustments",
    name: "Adjustments",
    component: EmptyPage,
    meta: { requiresClient: true, requiresClientManager: true },
  },
  {
    path: "/leave",
    name: "Leave",
    component: Leaves,
    meta: { requiresClient: true, requiresClientManager: true },
  },
  {
    path: "/overtime",
    name: "Overtime",
    component: Overtimes,
    meta: { requiresClient: true, requiresClientManager: true },
  },
  {
    path: "/contracts",
    name: "Contracts",
    component: EmptyPage,
    meta: { requiresClient: true, requiresClientManager: true },
  },
  {
    path: "/invoices",
    name: "Invoices",
    component: EmptyPage,
    meta: { requiresClient: true, requiresClientManager: true },
  },
  {
    path: "/reports",
    name: "Reports",
    component: Reports,
    meta: { requiresClient: true, requiresClientManager: true },
  },
  {
    path: "/integrations",
    name: "Integrations",
    component: EmptyPage,
    meta: { requiresClient: true, requiresClientManager: true },
  },
  {
    path: "/day-off",
    name: "Day-off",
    component: EmptyPage,
    meta: { requiresClient: true, requiresClientManager: true },
  },
  {
    path: "/work-expenses",
    name: "Work-expenses",
    component: Expense,
    meta: { requiresClient: true, requiresClientManager: true },
  },
  {
    path: "/documents",
    name: "Documents",
    component: Documents,
    meta: { requiresClient: true, requiresClientManager: true },
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: NotificationsView,
    meta: { requiresClient: true, requiresClientManager: true },
  },
  {
    path: "/client-managers",
    name: "ClientManagers",
    component: ClientManagers,
    meta: { requiresClient: true },
  },
];

export const clientGuard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  if (to.matched.some((record) => !record.meta.guest)) {
    if (
      to.matched[0].meta.requiresClient &&
      !to.matched[0].meta.requiresClientManager &&
      store.getters.role === "Client Manager"
    ) {
      next("/employees");
      return;
    }

    if (
      to.matched[0].meta.requiresClient &&
      to.matched[0].meta.requiresClientManager &&
      store.getters.isEmployee
    ) {
      next("/employee-documents");
      return;
    }

    next();
  } else {
    next();
  }
};
