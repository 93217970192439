import { Country, Currency, SalaryType } from "@/models/employee";
import { Commit } from "vuex";
export type State = {
  countries: Country[];
  currencies: Currency[];
  salary_types: SalaryType[];
  featureFlags: FeatureFlag[];
  rates: string[];
  isLoading: boolean;
  notificationCounter: number;
  newNotification: Notification;
};
export type SelectType = {
  name: string;
  value: Object;
};
export type FeatureFlag = {
  name: string;
  value: Object;
};
const state: State = {
  countries: [],
  currencies: [],
  salary_types: [],
  rates: [],
  featureFlags: [],
  isLoading: false,
  notificationCounter: 0,
  newNotification: {} as Notification,
};

const getters = {
  stateCountry: (state: State) => state.countries,
  isLoading: (state: State) => state.isLoading,
  rates: (state: State) => state.rates,
  notificationCounter: (state: State) => state.notificationCounter,
  newNotification: (state: State) => state.newNotification,
  featureFlags: (state: State) => state.featureFlags,
  baseCountriesOptions: (state: State) =>
    state.countries
      .sort((item1: Country) => (item1.active ? -1 : 1))
      .map((item: Country) => {
        return {
          value: item.uuid,
          name: item.name,
          disabled: !item.active,
          currency: item.currency,
        };
      }),
  baseCurrenciesOptions: (state: State) =>
    state.currencies.map((item: Currency) => {
      return {
        value: item.uuid,
        name: item.name,
      };
    }),
  baseSalaryTypesOptions: (state: State) =>
    state.salary_types.map((item: SalaryType) => {
      return {
        value: item.uuid,
        name: item.name,
      };
    }),
};

const actions = {
  async Register({ dispatch }: any, data: any) {
    await dispatch("LogIn", data);
  },

  async LogIn({ commit }: { commit: Commit }, user: any) {
    await commit("setUser", user);
  },

  async LogOut({ commit }: { commit: Commit }) {
    let user = null;
    commit("logout", user);
  },
};

const mutations = {
  setCountry(state: State, countries: Country[]) {
    state.countries = countries;
  },
  setCurrency(state: State, currencies: Currency[]) {
    state.currencies = currencies;
  },
  setRates(state: State, rateData: string[]) {
    state.rates = rateData;
  },
  setSalaryType(state: State, salary_types: SalaryType[]) {
    state.salary_types = salary_types;
  },
  setFeatureFlags(state: State, featureFlags: FeatureFlag[]) {
    state.featureFlags = featureFlags;
  },
  setIsLoading(state: State, loading: boolean) {
    state.isLoading = loading;
  },
  setNotificationCounter(state: State, counter: number) {
    state.notificationCounter = counter;
  },
  setNewNotification(state: State, newNotification: Notification) {
    state.newNotification = newNotification;
  },
  incrementNotificationCounter(state: State) {
    state.notificationCounter += 1;
  },
  decrementNotificationCounter(state: State) {
    state.notificationCounter -= 1;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
