import { IsNotEmpty, IsNumber, IsOptional, Min } from 'class-validator';

export class EmployeeSalaryDto {
  @IsNotEmpty()
  currencyUuid?: string;

  @IsNotEmpty()
  salaryTypeUuid?: string;

  @IsNotEmpty()
  @IsNumber()
  @Min(0)
  basicSalary?: number;

  @IsOptional()
  @IsNumber()
  @Min(0)
  otherAllowance1?: number;

  @IsOptional()
  @IsNumber()
  @Min(0)
  schoolAllowance?: number;

  @IsOptional()
  @IsNumber()
  @Min(0)
  otherAllowance2?: number;

  @IsOptional()
  @IsNumber()
  @Min(0)
  housingAllowance?: number;

  @IsOptional()
  @IsNumber()
  @Min(0)
  transportAllowance?: number;

  @IsOptional()
  @IsNumber()
  @Min(0)
  foodAllowance?: number;
}
