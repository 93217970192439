import { SortingDirectionEnum } from './enums';

export class PaginationQueryRequestDto {
  page?: number;

  limit?: number;

  search?: string;

  sortField?: string;

  sortDirection?: SortingDirectionEnum;
}
