import { IsEnum, IsNumber, IsString, MaxLength, Min, IsDateString } from 'class-validator';
import { EmployeeOvertimeRequestType } from '../../enums';

export class EmployeeOvertimeRequestCreateRequestDto {
  @IsEnum(EmployeeOvertimeRequestType)
  type: EmployeeOvertimeRequestType;

  @IsDateString()
  date: Date;

  @IsNumber({ allowNaN: false, allowInfinity: false })
  @Min(0)
  amount: number;

  @IsString()
  @MaxLength(500)
  comment: string;
}
