<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.2133 19.071C18.5505 19.7338 17.4742 19.7335 16.8109 19.0702L5.07197 7.33125C4.40868 6.66796 4.40832 5.59168 5.07115 4.92885C5.73399 4.26601 6.81027 4.26637 7.47356 4.92966L19.2125 16.6686C19.8758 17.3319 19.8761 18.4081 19.2133 19.071Z"
      :fill="color || '#0A1E46'"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.213 7.33028L7.47242 19.0708C6.80798 19.7353 5.73331 19.7333 5.07002 19.07C4.40673 18.4067 4.40476 17.3321 5.0692 16.6676L16.8098 4.92706C17.4726 4.26423 18.5489 4.26459 19.2122 4.92788C19.8754 5.59117 19.8758 6.66745 19.213 7.33028Z"
      :fill="color || '#0A1E46'"
    />
  </svg>
</template>

<script>
export default {
  name: "PlusSVGComponent",
  props: {
    color: {
      type: String,
      required: false,
    },
  },
};
</script>
