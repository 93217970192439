<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.85116 8.97698C7.40516 8.97698 6.96049 8.82965 6.58849 8.53498L3.59849 6.12432C3.38316 5.95098 3.34982 5.63565 3.52249 5.42098C3.69649 5.20698 4.01116 5.17298 4.22582 5.34565L7.21316 7.75365C7.58849 8.05098 8.11716 8.05098 8.49516 7.75098L11.4525 5.34698C11.6672 5.17165 11.9818 5.20498 12.1565 5.41965C12.3305 5.63365 12.2978 5.94832 12.0838 6.12298L9.12116 8.53098C8.74649 8.82832 8.29849 8.97698 7.85116 8.97698Z"
      fill="#666F88"
    />
    <mask
      id="mask0_2142_43390"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="1"
      width="15"
      height="14"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.666504 1.33398H14.9998V14.334H0.666504V1.33398Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_2142_43390)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.55917 13.334H11.1058C11.1072 13.3327 11.1125 13.334 11.1165 13.334C11.8772 13.334 12.5518 13.062 13.0692 12.5453C13.6698 11.9473 13.9998 11.088 13.9998 10.126V5.54732C13.9998 3.68532 12.7825 2.33398 11.1058 2.33398H4.5605C2.88384 2.33398 1.6665 3.68532 1.6665 5.54732V10.126C1.6665 11.088 1.99717 11.9473 2.59717 12.5453C3.1145 13.062 3.78984 13.334 4.54984 13.334H4.55917ZM4.54784 14.334C3.51917 14.334 2.6005 13.9607 1.89117 13.254C1.10117 12.466 0.666504 11.3553 0.666504 10.126V5.54732C0.666504 3.14532 2.3405 1.33398 4.5605 1.33398H11.1058C13.3258 1.33398 14.9998 3.14532 14.9998 5.54732V10.126C14.9998 11.3553 14.5652 12.466 13.7752 13.254C13.0665 13.96 12.1472 14.334 11.1165 14.334H11.1058H4.5605H4.54784Z"
        fill="#666F88"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "MessageSVGComponent",
  props: {
    color: {
      type: String,
      required: false,
    },
  },
};
</script>
