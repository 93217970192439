import { IsBoolean, IsNumber, IsOptional, Min } from 'class-validator';

export class EmployeeOvertimeDto {
  @IsOptional()
  @IsBoolean()
  isOtApplicable?: boolean;

  @IsOptional()
  @IsNumber()
  @Min(0)
  OT1hourlyRate?: number;

  @IsOptional()
  @IsNumber()
  @Min(0)
  OT2hourlyRate?: number;
}
