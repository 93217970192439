import { capitalizeFirstLetter, formatDate } from "@/utils/data";
import {
  EmployeeGenders,
  EmployeeLeaveRequestEmployeeResponseDto,
  EmployeeLeaveRequestStatus,
} from "@/dto/src";
import { LeaveTypesOptions } from "../constants";
import i18n from "@/i18n";

class EmployeeLeaveFormatService {
  constructor() {}

  private employeeLeaveColumnNames = [
    {
      name: i18n.global.t("elements.elements-requested-on-text"),
      width: "200",
    },
    {
      name: i18n.global.t("elements.elements-leave-type-text"),
      width: "200",
    },
    {
      name: i18n.global.t("elements.elements-from-text"),
      width: "200",
    },
    {
      name: i18n.global.t("elements.elements-to-text"),
      width: "200",
    },
    {
      name: i18n.global.t("elements.elements-number-of-days-text"),
      width: "120",
    },
    {
      name: i18n.global.t("elements.elements-comment-text"),
      width: "70",
    },
    {
      name: i18n.global.t("elements.elements-status-text"),
      width: "160",
    },
    {
      name: i18n.global.t("elements.elements-actions-text"),
      width: "120",
    },
  ];

  private employeeLeaveFieldNames = [
    "updatedAt",
    "typeName",
    "formattedFrom",
    "formattedTo",
    "businessDays",
    "comment",
    "status",
    "action",
  ];

  private getLeaveTypeName(type: string, gender: EmployeeGenders): string {
    return (
      LeaveTypesOptions(gender).find((leaveType) => leaveType.value === type)
        ?.name || ""
    );
  }

  public getEmployeeLeavesInTableFormat(
    leaves: EmployeeLeaveRequestEmployeeResponseDto[]
  ) {
    const data = leaves.map((leave) => {
      return {
        id: leave.uuid,
        updatedAt: formatDate(leave.updatedAt),
        type: leave.type || "",
        typeName: this.getLeaveTypeName(
          leave.type,
          leave.employeeGender || ("male" as EmployeeGenders)
        ),
        formattedFrom: formatDate(leave.from || ""),
        formattedTo: formatDate(leave.to || ""),
        businessDays: leave.daysCount,
        from: leave.from || "",
        to: leave.to || "",
        certificate: leave.certificate || "",
        comment: {
          type: "customComponent",
          component: "comment",
          text: leave.comment || "",
        },
        status: {
          type: "customComponent",
          component: "statusWithComment",
          statusType: leave.status,
          statusText: capitalizeFirstLetter(leave.status),
          text: leave.clientComment || "",
          awaitingAfterApproval: true,
        },
        action: {
          type: "customComponent",
          component: "approveAction",
          actionTypes: ["edit", "remove"],
          isDisabled: leave.status !== EmployeeLeaveRequestStatus.PENDING,
        },
      };
    });
    return {
      columnNames: this.employeeLeaveColumnNames,
      fieldsNames: this.employeeLeaveFieldNames,
      tableData: data,
    };
  }
}

export default new EmployeeLeaveFormatService();
