import {
  EmployeeDocumentRequestType,
  EmployeeOvertimeRequestType,
  EmployeeLeaveRequestType,
  EmploymentType,
  EmployeeGenders,
} from "@/dto/src";
import { PregnancyTypeMapper } from "@/utils/mappers";

export enum STATUSES {
  dismissed = "dismissed",
  active = "active",
  pending = "pending",
}

export type SelectOption = {
  name: string;
  value: string | number;
  disabled?: boolean;
};

export const employeeColumnNames = [
  {
    name: "Full Name",
    width: "228",
    sortable: true,
  },
  {
    name: "Employee ID",
    width: "250",
    sortable: true,
  },
  {
    name: "Position",
    width: "132",
    sortable: true,
  },
  {
    name: "Start Date",
    width: "136",
    sortable: true,
  },
  {
    name: "Contract Tenure",
    width: "85",
    sortable: true,
  },
  {
    name: "Operating Country",
    width: "100",
    sortable: true,
  },
  {
    name: "Employment Type",
    width: "133",
    sortable: true,
  },
  {
    name: "Status",
    width: "190",
    sortable: true,
  },
];

export const employeeFieldsNames = [
  "fullName",
  "employeeId",
  "position",
  "startDate",
  "contractTenure",
  "country",
  "employmentType",
  "status",
];

export const reportColumnNames = [
  {
    name: "Employee",
    width: "228",
  },
  {
    name: "Position",
    width: "132",
  },
  {
    name: "Operating Country",
    width: "190",
  },
  {
    name: "Progress",
    width: "163",
  },
  {
    name: "Completion date",
    width: "162",
  },
  {
    name: "Onboarding Status",
    width: "163",
  },
  {
    name: "Onboarding Notes",
    width: "160",
  },
];

export const reportFieldsNames = [
  "name",
  "position",
  "operation_country",
  "progress",
  "start_date",
  "status",
  "onboardingNotes",
];

export const documentRequestColumnNames = [
  {
    name: "Document Type",
    width: "290",
  },
  {
    name: "Requested On",
    width: "135",
  },
  {
    name: "Comment",
    width: "74",
  },
  {
    name: "Status",
    width: "150",
  },
  {
    name: "Actions",
    width: "140",
  },
];

export const documentRequestClientColumnNames = [
  {
    name: "Employee",
    width: "290",
  },
  {
    name: "Document Type",
    width: "290",
  },
  {
    name: "Requested On",
    width: "135",
  },
  {
    name: "Issued On",
    width: "135",
  },
  {
    name: "Comment",
    width: "74",
  },
  {
    name: "Status",
    width: "170",
  },
];

export const workExpenseColumnNames = [
  {
    name: "Requested On",
    width: "135",
  },
  {
    name: "Expense Type",
    width: "290",
  },
  {
    name: "Sum",
    width: "87",
  },
  {
    name: "Comment",
    width: "70",
  },
  {
    name: "Status",
    width: "150",
  },
  {
    name: "Actions",
    width: "110",
  },
];

export const workExpenseClientColumnNames = [
  {
    name: "Employee",
    width: "290",
  },
  {
    name: "Requested On",
    width: "135",
  },
  {
    name: "Expense Type",
    width: "290",
  },
  {
    name: "Sum",
    width: "74",
  },
  {
    name: "Comment",
    width: "74",
  },
  {
    name: "Doc",
    width: "54",
  },
  {
    name: "Status",
    width: "140",
  },
  {
    name: "Actions",
    width: "62",
  },
];

export const workExpenseFieldsNames = [
  "createdAt",
  "type",
  "displayAmount",
  "comment",
  "status",
  "action",
];

export const documentRequestFieldsNames = [
  "type",
  "createdAt",
  "comment",
  "status",
  "action",
];

export const documentRequestClientFieldsNames = [
  "name",
  "type",
  "createdAt",
  "issuedOn",
  "comment",
  "status",
];

export const workExpenseClientFieldsNames = [
  "name",
  "createdAt",
  "type",
  "amount",
  "comment",
  "doc",
  "status",
  "action",
];

export type OptionType = typeof EmploymentType;

export const DocumentTypesOptions = [
  {
    name: "Employment Certificate",
    value: EmployeeDocumentRequestType.EMPLOYMENT_CERTIFICATE,
  },
  {
    name: "Experience Certificate",
    value: EmployeeDocumentRequestType.EXPERIENCE_CERTIFICATE,
  },
  {
    name: "NOC 1 (Obtain Visit Visa/Entry Permit)",
    value: EmployeeDocumentRequestType.NOC1,
  },
  {
    name: "NOC 2 (Obtain Driving License)",
    value: EmployeeDocumentRequestType.NOC2,
  },
  {
    name: "NOC 3 (Obtain Alcohol License)",
    value: EmployeeDocumentRequestType.NOC3,
  },
  {
    name: "NOC 4 (Other)",
    value: EmployeeDocumentRequestType.NOC4,
  },
  {
    name: "Salary certificate",
    value: EmployeeDocumentRequestType.SALARY_CERTIFICATE,
  },
  {
    name: "Salary transfer letter",
    value: EmployeeDocumentRequestType.SALARY_TRANSFER_LETTER,
  },
];

export const OTOptions = [
  {
    name: "OT1",
    value: EmployeeOvertimeRequestType.OT1,
  },
  {
    name: "OT2",
    value: EmployeeOvertimeRequestType.OT2,
  },
];

export const LeaveTypesOptions = (gender: EmployeeGenders) => [
  {
    name: "Annual Leave",
    value: EmployeeLeaveRequestType.ANNUAL,
  },
  {
    name: "Sick Leave",
    value: EmployeeLeaveRequestType.SICK,
  },
  {
    name: PregnancyTypeMapper(gender),
    value: EmployeeLeaveRequestType.MATERNITY_PATERNITY,
  },
  {
    name: "Unpaid Leave",
    value: EmployeeLeaveRequestType.UNPAID,
  },
];

export enum EmployeeWorkExpenseType {
  // Flight expenses
  FLIGHT = "flight",
  // Hotel expenses
  HOTEL = "hotel",
  // Fuel expenses
  FUEL = "fuel",
  // Mobile phone expenses
  MOBILE_PHONE = "mobile-phone",
  // Client entertainment expenses
  CLIENT_ENTERTAINMENT = "client-entertainment",
  // F&B expenses
  F_B = "f-b",
  // Training expenses
  TRAINING = "training",
  // Other expenses
  OTHER = "other",
}

export const WorkExpensesOptions = [
  {
    name: "Flight expenses",
    value: EmployeeWorkExpenseType.FLIGHT,
  },
  {
    name: "Hotel expenses",
    value: EmployeeWorkExpenseType.HOTEL,
  },
  {
    name: "Fuel expenses",
    value: EmployeeWorkExpenseType.FUEL,
  },
  {
    name: "Mobile phone expenses",
    value: EmployeeWorkExpenseType.MOBILE_PHONE,
  },
  {
    name: "Client entertainment expenses",
    value: EmployeeWorkExpenseType.CLIENT_ENTERTAINMENT,
  },
  {
    name: "F&B expenses",
    value: EmployeeWorkExpenseType.F_B,
  },
  {
    name: "Training expenses",
    value: EmployeeWorkExpenseType.TRAINING,
  },
  {
    name: "Other expenses",
    value: EmployeeWorkExpenseType.OTHER,
  },
];
