<template>
  <div class="divider" />
</template>

<style lang="scss" scoped>
.divider {
  width: 1px;
  height: 100%;
  background: $base-middle-gray;
  opacity: 0.4;
}
</style>
