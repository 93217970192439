import { ClientEmployeeRequestDto } from "@/dto/src";
import { IsNotEmpty, IsNumber, Min } from "class-validator";
import applyMixins from "../base/applyMixins";
import BaseModel from "../base/BaseModel";
class AdditionalValidationClass extends ClientEmployeeRequestDto {
  @IsNotEmpty()
  @IsNumber()
  @Min(0)
  sum: number;
}

class EmployeeRequest {}

interface EmployeeRequest extends BaseModel, AdditionalValidationClass {}

applyMixins(EmployeeRequest, [BaseModel, AdditionalValidationClass]);

export default EmployeeRequest;
