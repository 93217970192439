import axiosReq from "@/utils/axiosReq";
import { AxiosError, AxiosResponse } from "axios";

import { notify } from "@kyvg/vue3-notification";
import { OvertimeRequestType } from "./constants";

class EmployeeOvertimeApiService {
  constructor() {}

  public getAllEmployeesOvertimeRequests(
    page: number,
    limit = 15
  ): AxiosResponse {
    return axiosReq({
      url: `/employees/overtime-requests?page=${page}&limit=${limit}`,
      method: "get",
    }).catch((err: AxiosError) => {
      notify({
        title: "Error load",
        text: err.message,
        type: "error",
      });
    });
  }

  public addEmployeeOvertimeRequest(
    uuid: string,
    overtimeRequest: OvertimeRequestType
  ): AxiosResponse {
    return axiosReq({
      url: `/employees/${uuid}/overtime-requests`,
      method: "post",
      data: overtimeRequest,
    }).catch((err: AxiosError) => {
      notify({
        title: "Error load Employees",
        text: err.message,
        type: "error",
      });
    });
  }

  public updateEmployeeOvertimeRequest(
    uuid: string,
    overtimeUuid: string,
    overtimeRequest: OvertimeRequestType
  ): AxiosResponse {
    return axiosReq({
      url: `/employees/${uuid}/overtime-requests/${overtimeUuid}`,
      method: "put",
      data: overtimeRequest,
    }).catch((err: AxiosError) => {
      notify({
        title: "Error load Employees",
        text: err.message,
        type: "error",
      });
    });
  }

  public deleteEmployeeOvertime(
    uuid: string,
    overtimeUuid: string
  ): AxiosResponse {
    return axiosReq({
      url: `/employees/${uuid}/overtime-requests/${overtimeUuid}`,
      method: "delete",
    }).catch((err: AxiosError) => {
      if (err.response?.status === 409) {
        notify({
          title: "Error create",
          text: err.response.data.message,
          type: "error",
        });
        return;
      }
      Object.values(
        (
          err.response as {
            data: {
              message: [];
            };
          }
        ).data.message
      )
        .flat()
        .forEach((text: string) => {
          notify({
            title: "Error",
            text,
            type: "error",
          });
        });
    });
  }
}

export default new EmployeeOvertimeApiService();
