import { EmployeeDto } from './employee.dto';
import { IsNotEmpty, IsOptional, IsString } from 'class-validator';

export class EmployeeGeneralRequestDto extends EmployeeDto {
  @IsNotEmpty()
  @IsString()
  countryUuid: string;

  @IsOptional()
  @IsString()
  clientUuid?: string;
}
