<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.3"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.83342 1.04175C3.18705 1.04175 1.04175 3.18705 1.04175 5.83341V14.1667C1.04175 16.8131 3.18705 18.9584 5.83342 18.9584H14.1667C16.8131 18.9584 18.9584 16.8131 18.9584 14.1667V5.83341C18.9584 3.18705 16.8131 1.04175 14.1667 1.04175H5.83342Z"
      :fill="color || '#0A1E46'"
    />
    <path
      d="M6.66675 14.1667L6.66675 11.6667"
      :stroke="color || '#0A1E46'"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10 14.1667L10 5.83341"
      :stroke="color || '#0A1E46'"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.3335 14.1667L13.3335 8.33341"
      :stroke="color || '#0A1E46'"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "ReportsSVGComponent",
  props: {
    color: {
      type: String,
      required: false,
    },
  },
};
</script>
