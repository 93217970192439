import moment from "moment";
import get from "lodash/get";

export function removeEmpty(obj: any) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => !!v)) as any;
}

export function filterObjectFields<T>(object: any, removedFiled: string[]): T {
  return Object.keys(object)
    .filter((key) => !removedFiled.includes(key))
    .reduce((obj: any, key) => {
      obj[key] = object[key];
      return obj;
    }, {});
}

export function formatDate(date: string | Date = "") {
  if (!date) {
    return "Empty Date";
  }
  const newDate = new Date(date);
  return moment(newDate).format("DD MMM YYYY");
}

export const DAYS = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export function formatWeekendDays(weekendDays: Array<number>) {
  let str = "";
  if (!weekendDays) {
    return;
  }
  weekendDays.forEach((day, index) => {
    str += DAYS[day - 1];
    if (weekendDays.length === 1 || weekendDays.length - index - 1 < 1) {
      return;
    }

    str += " & ";
  });
  return str;
}

export function isArrayValuesInAnotherArray(
  arr: Array<string>,
  target: Array<string>
) {
  return target.every((v) => arr.includes(v));
}

export function isAnyArrayValuesInAnotherArray(
  arr: Array<string>,
  target: Array<string>
) {
  return target.some((v) => arr.includes(v));
}

export function addDays(date: Date | string, days: number) {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
}

export const roundNumber = (value: number): number => {
  if (!value) {
    return 0;
  }
  return +parseFloat(value.toString()).toFixed(2);
};

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const isNumberInInput = (evt: KeyboardEvent, value: number) => {
  const keysAllowed: string[] = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
  ];
  const keyPressed: string = evt.key;

  if (!keysAllowed.includes(keyPressed)) {
    evt.preventDefault();
  }
};

export const isLimitLength = (
  evt: KeyboardEvent,
  value: string,
  limit: number
) => {
  let l = 0;

  if ((evt as any).clipboardData) {
    l = (evt as any).clipboardData.getData("text").length;
  }

  if (l + value.length > limit) {
    evt.preventDefault();
  }
};

export const getBusinessDaysCount = (from: Date, to: Date) => {
  let count = 0;

  const startDate = new Date(from);
  const endDate = new Date(to);

  const curDate = new Date(startDate.getTime());
  while (curDate <= endDate) {
    // const dayOfWeek = curDate.getDay();
    // if(dayOfWeek !== 0 && dayOfWeek !== 6) count++; // TODO: should check weekend days in future, for now it counts all days
    count++;
    curDate.setDate(curDate.getDate() + 1);
  }

  return count;
};

export const getWorkingPeriodMonths = (startDate: string | Date) => {
  const from = new Date(startDate);
  const to = new Date();

  let months =
    to.getMonth() -
    from.getMonth() +
    12 * (to.getFullYear() - from.getFullYear());

  if (to.getDate() < from.getDate()) {
    var newFrom = new Date(to.getFullYear(), to.getMonth(), from.getDate());
    if (
      to < newFrom &&
      to.getMonth() == newFrom.getMonth() &&
      to.getFullYear() % 4 != 0
    ) {
      months--;
    }
  }

  return months;
};

export const MONTH_OPTIONS = [
  { label: "January", value: 1 },
  { label: "February", value: 2 },
  { label: "March", value: 3 },
  { label: "April", value: 4 },
  { label: "May", value: 5 },
  { label: "June", value: 6 },
  { label: "July", value: 7 },
  { label: "August", value: 8 },
  { label: "September", value: 9 },
  { label: "October", value: 10 },
  { label: "November", value: 11 },
  { label: "December", value: 12 },
];

export const getMonthOptions = (startDate: Date | string, year: number) => {
  const start = new Date(startDate);
  const now = new Date();

  let startIndex = 0;
  if (year === start.getFullYear()) {
    startIndex = MONTH_OPTIONS.findIndex(
      (monthOption) => monthOption.value === start.getMonth() + 1
    );
  }

  let lastIndex = MONTH_OPTIONS.length - 1;
  if (year === now.getFullYear()) {
    const currentMonth = now.getMonth() + 1;
    lastIndex = MONTH_OPTIONS.findIndex((m) => m.value === currentMonth);
  }

  return MONTH_OPTIONS.slice(startIndex, lastIndex + 1);
};

export const getYearOptions = (start: Date, end: Date) => {
  const from = new Date(start);
  const to = new Date(end);

  var yearOptions = [];

  var currentDate = from;
  while (currentDate.getFullYear() <= to.getFullYear()) {
    yearOptions.push({
      label: currentDate.getFullYear().toString(),
      value: currentDate.getFullYear(),
    });
    currentDate = new Date(
      currentDate.getFullYear() + 1,
      currentDate.getMonth()
    );
  }
  return yearOptions;
};

export const getField = (obj: Object) => {
  return {
    field: Object.entries(obj)[0][0],
    type: Object.entries(obj)[0][1],
  };
};
