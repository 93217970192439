import { IsEnum, IsNotEmpty, IsNumber, IsOptional, IsString, IsUUID, MaxLength, Min } from 'class-validator';
import { EmployeeWorkExpenseType } from '../../enums';

export class EmployeeWorkExpenseCreateRequestDto {
  @IsEnum(EmployeeWorkExpenseType)
  type: EmployeeWorkExpenseType;

  @IsString()
  @IsNotEmpty()
  @IsUUID()
  currencyUuid: string;

  @IsNumber({ allowNaN: false, allowInfinity: false })
  @Min(0)
  amount: number;

  fileProof?: string;

  @IsOptional()
  @IsString()
  @MaxLength(500)
  comment?: string;
}
