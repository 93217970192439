import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import SignUp from "@/views/client/Login/SignUp.vue";
import SuccessPageUser from "@/views/client/Login/SuccessPageUser.vue";
import SuccessPageEmployee from "@/views/client/Login/SuccessPageEmployee.vue";
import EmptyPage from "@/views/client/EmptyPage/EmptyPage.vue";

import store from "../store";
import { clientRoutes, clientGuard } from "./client";
import { employeeRoutes, employeeGuard } from "./employee";

const routes: Array<RouteRecordRaw> = [
  ...clientRoutes,
  ...employeeRoutes,
  {
    path: "/login",
    name: "Login",
    component: SignUp,
    meta: { guest: true },
  },
  {
    path: "/empty-page",
    name: "EmptyPage",
    component: EmptyPage,
    meta: { requiresClient: true, requiresEmployee: true },
  },
  {
    path: "/success-user",
    name: "SuccessUser",
    component: SuccessPageUser,
    meta: { guest: true },
  },
  {
    path: "/success-employee",
    name: "SuccessEmployee",
    component: SuccessPageEmployee,
    meta: { guest: true },
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/employees",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Client guard
router.beforeEach(clientGuard);

// Employee guard
router.beforeEach(employeeGuard);

// Guest guard
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters.isAuthenticated) {
      next("/employees");
      return;
    }
    next();
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => !record.meta.guest)) {
    if (!store.getters.isAuthenticated) {
      next("/login");
      return;
    }

    next();
  } else {
    next();
  }
});

// Feature flag guard
router.beforeEach((to, from, next) => {
  const activeFlag = store.getters.featureFlags.find(
    (item: any) => item.name === to.path.substring(1)
  );
  if (!activeFlag || (activeFlag && activeFlag.active)) {
    next();
    return;
  } else {
    next("/empty-page");
  }
});

export default router;
