<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.83301 15.0002C7.55701 15.0002 7.33301 14.7762 7.33301 14.5002V12.3928C7.33301 12.1168 7.55701 11.8928 7.83301 11.8928C8.10901 11.8928 8.33301 12.1168 8.33301 12.3928V14.5002C8.33301 14.7762 8.10901 15.0002 7.83301 15.0002Z"
      :fill="color || '#0A1E46'"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.833 1.66675C6.61567 1.66675 5.625 2.66275 5.625 3.88741V7.01275C5.625 8.23608 6.61567 9.23275 7.833 9.23275C9.05033 9.23275 10.041 8.23608 10.041 7.01275V3.88741C10.041 2.66275 9.05033 1.66675 7.833 1.66675ZM7.833 10.2327C6.06433 10.2327 4.625 8.78808 4.625 7.01275V3.88741C4.625 2.11141 6.06433 0.666748 7.833 0.666748C9.60167 0.666748 11.041 2.11141 11.041 3.88741V7.01275C11.041 8.78808 9.60167 10.2327 7.833 10.2327Z"
      :fill="color || '#0A1E46'"
    />
    <mask
      id="mask0_246_4046"
      style="mask-type:alpha"
      maskUnits="userSpaceOnUse"
      x="2"
      y="6"
      width="12"
      height="7"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2 6.53394H13.6663V12.8926H2V6.53394Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_246_4046)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.83284 12.8926C4.61618 12.8926 1.99951 10.2646 1.99951 7.03394C1.99951 6.75794 2.22351 6.53394 2.49951 6.53394C2.77618 6.53394 2.99951 6.75794 2.99951 7.03394C2.99951 9.7126 5.16751 11.8926 7.83284 11.8926C10.4982 11.8926 12.6662 9.7126 12.6662 7.03394C12.6662 6.75794 12.8902 6.53394 13.1662 6.53394C13.4428 6.53394 13.6662 6.75794 13.6662 7.03394C13.6662 10.2646 11.0495 12.8926 7.83284 12.8926Z"
        :fill="color || '#0A1E46'"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "VoiceSVGComponent",
  props: {
    color: {
      type: String,
      required: false,
    },
  },
};
</script>
