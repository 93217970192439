<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.3"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.83341 1.04175C3.18705 1.04175 1.04175 3.18705 1.04175 5.83342V14.1667C1.04175 16.8131 3.18705 18.9584 5.83341 18.9584H14.1667C16.8131 18.9584 18.9584 16.8131 18.9584 14.1667V5.83342C18.9584 3.18705 16.8131 1.04175 14.1667 1.04175H5.83341Z"
      :fill="color || '#0A1E46'"
    />
    <path
      d="M10 5.8335V9.75016C10 9.90754 10.0741 10.0557 10.2 10.1502L13.3333 12.5002"
      :stroke="color || '#0A1E46'"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  name: "OvertimeSVGComponent",
  props: {
    color: {
      type: String,
      required: false,
    },
  },
};
</script>
