import {
    AddClientRequestDto,
} from "@/dto/src";
import applyMixins from "../base/applyMixins";
import BaseModel from "../base/BaseModel";

class ClientRequest { }

interface ClientRequest extends BaseModel, AddClientRequestDto { }

applyMixins(ClientRequest, [BaseModel, AddClientRequestDto]);

export default ClientRequest;