<template>
  <div class="card" v-bind="$attrs">
    <img class="card-icon" :src="imgSrc" alt="bag" />
    <p class="type-text">{{ title }}</p>
    <p class="description-text">
      {{ description }}
    </p>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "CardWIthIcon",
  props: {
    imgSrc: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: "44px",
    },
  },
});
</script>

<style lang="scss" scoped>
.card {
  cursor: pointer;
  width: 356px;
  height: 178px;
  background: $base-light-gray;
  border: 1px solid $base-middle-gray;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 32px;

  .type-text {
    margin-top: 18px;
    @include text(16px, 16px, bold);
    letter-spacing: 0.01em;
    color: $base-gray;
    opacity: 0.5;
  }

  .description-text {
    margin-top: 6px;
    @include text(14px, 24px, 500);

    color: $base-gray;
    opacity: 0.5;
  }
}
</style>
