import { AxiosError, AxiosResponse } from "axios";
import { notify } from "@kyvg/vue3-notification";

import axiosReq from "@/utils/axiosReq";
import { LeaveRequestType } from "./constants";

class EmployeeLeaveApiService {
  constructor() {}

  public getLeaveRequests(page: number = 1, limit: number = 15): AxiosResponse {
    return axiosReq({
      url: `/employees/leave-requests?page=${page}&limit=${limit}`,
      method: 'get',
    }).catch((err: AxiosError) => {
      notify({
        title: "Error load leave requests",
        text: err.message,
        type: "error",
      });
    }) 
  }

  public getLeaveStatistic(): AxiosResponse {
    return axiosReq({
      url: `/employees/leave-requests/statistics`,
      method: 'get',
    }).catch((err: AxiosError) => {
      notify({
        title: "Error load leave requests",
        text: err.message,
        type: "error",
      });
    }) 
  }

  public addEmployeeLeaveRequest(
    uuid: string,
    leaveRequest: LeaveRequestType
  ): AxiosResponse {
    return axiosReq({
      url: `/employees/${uuid}/leave-requests`,
      method: "post",
      data: leaveRequest,
    }).catch((err: AxiosError) => {
      notify({
        title: "Error add leave request",
        text: err.message,
        type: "error",
      });
    });
  }

  public updateEmployeeLeaveRequest(
    uuid: string,
    leaveUuid: string,
    leaveRequest: LeaveRequestType
  ): AxiosResponse {
    return axiosReq({
      url: `/employees/${uuid}/leave-requests/${leaveUuid}`,
      method: "put",
      data: leaveRequest,
    }).catch((err: AxiosError) => {
      notify({
        title: "Error update leave request",
        text: err.message,
        type: "error",
      });
    });
  }

  public deleteEmployeeLeaveRequest(
    uuid: string,
    leaveUuid: string
  ): AxiosResponse {
    return axiosReq({
      url: `/employees/${uuid}/leave-requests/${leaveUuid}`,
      method: "delete",
    }).catch((err: AxiosError) => {
      if (err.response?.status === 409) {
        notify({
          title: "Error delete leave request",
          text: err.response.data.message,
          type: "error",
        });
        return;
      }
      Object.values(
        (
          err.response as {
            data: {
              message: [];
            };
          }
        ).data.message
      )
        .flat()
        .forEach((text: string) => {
          notify({
            title: "Error delete leave request",
            text,
            type: "error",
          });
        });
    });
  }
}

export default new EmployeeLeaveApiService();
