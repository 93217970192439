import { EmployeeGenders, EmploymentType } from "@/dto/src";
import i18n from "@/i18n";
const EmployeeTypeMapper = {
  [EmploymentType.EMPLOYER_OF_RECORD]: i18n.global.t(
    "employee.employee-option-title-eor"
  ),
  [EmploymentType.CONTRACTOR]: i18n.global.t(
    "employee.employee-option-title-contractor"
  ),
  [EmploymentType.PAYROLL]: i18n.global.t(
    "employee.employee-option-title-payroll"
  ),
  [EmploymentType.FREELANCE]: i18n.global.t(
    "employee.employee-option-title-freelance"
  ),
  [EmploymentType.MANAGEMENT]: i18n.global.t(
    "employee.employee-option-title-management"
  ),
  [EmploymentType.OTHERS]: i18n.global.t(
    "employee.employee-option-title-others"
  ),
};

const PregnancyTypeMapper = (gender: EmployeeGenders) => {
  return gender === EmployeeGenders.FEMALE
    ? i18n.global.t("employee.employee-maternity")
    : i18n.global.t("employee.employee-paternity");
};

export { EmployeeTypeMapper, PregnancyTypeMapper };
