<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.3"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.9223 15.766C16.9677 15.772 17.0131 15.7782 17.0584 15.7845L17.2863 15.8165C18.2787 15.9558 19.1665 15.1994 19.1665 14.2147V13.4633C19.1665 12.7591 18.7158 12.1309 18.0407 11.894C17.2606 11.6204 16.4553 11.4422 15.6424 11.3595C16.4906 11.9728 17.0167 12.9658 17.0167 14.0493V14.9823C17.0167 15.2532 16.984 15.5156 16.9223 15.766ZM13.4841 10.3598C13.8222 10.4993 14.1935 10.5765 14.5832 10.5765C16.151 10.5765 17.4219 9.32808 17.4219 7.78812C17.4219 6.24815 16.151 4.99976 14.5832 4.99976C14.5367 4.99976 14.4906 5.00085 14.4447 5.00302C14.7298 5.61019 14.8891 6.28814 14.8891 7.0033C14.8891 8.31706 14.3514 9.50523 13.4841 10.3598Z"
      :fill="color || '#0A1E46'"
    />
    <path
      opacity="0.3"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.39481 15.766C3.34944 15.772 3.30408 15.7782 3.25874 15.7845L3.03081 15.8165C2.03846 15.9558 1.15062 15.1994 1.15062 14.2147V13.4633C1.15062 12.7591 1.6013 12.1309 2.27646 11.894C3.05651 11.6204 3.86181 11.4422 4.67474 11.3595C3.82651 11.9728 3.30039 12.9658 3.30039 14.0493V14.9823C3.30039 15.2532 3.33318 15.5156 3.39481 15.766ZM6.83301 10.3598C6.49497 10.4993 6.12363 10.5765 5.73395 10.5765C4.16619 10.5765 2.89527 9.32808 2.89527 7.78812C2.89527 6.24815 4.16619 4.99976 5.73395 4.99976C5.7804 4.99976 5.82658 5.00085 5.87248 5.00302C5.58739 5.61019 5.42806 6.28814 5.42806 7.0033C5.42806 8.31706 5.96572 9.50523 6.83301 10.3598Z"
      :fill="color || '#0A1E46'"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.96066 12.101C8.68734 11.1272 11.667 11.1272 14.3937 12.101C15.2171 12.395 15.7667 13.175 15.7667 14.0493V14.9823C15.7667 16.2048 14.684 17.1438 13.4738 16.9709L13.1958 16.9312C11.1936 16.6452 9.16082 16.6452 7.15858 16.9312L6.88062 16.9709C5.6704 17.1438 4.58765 16.2048 4.58765 14.9823V14.0493C4.58765 13.175 5.13727 12.395 5.96066 12.101Z"
      :fill="color || '#0A1E46'"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.6391 7.00338C13.6391 5.09144 12.0892 3.5415 10.1772 3.5415C8.26527 3.5415 6.71533 5.09144 6.71533 7.00338C6.71533 8.91533 8.26527 10.4653 10.1772 10.4653C12.0892 10.4653 13.6391 8.91533 13.6391 7.00338Z"
      :fill="color || '#0A1E46'"
    />
  </svg>
</template>

<script>
export default {
  name: "UsersSVGComponent",
  props: {
    color: {
      type: String,
      required: false,
    },
  },
};
</script>
