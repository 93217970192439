import {
  ClientPayrollCycleResponseDto,
  PayrolCycleStatus,
  PayrollCycleStep,
} from "@/dto/src";
import i18n from "@/i18n";
import { PayrollCycleType } from "@/types";
import { formatDate } from "@/utils/data";

class ClientPayrollCycleFormatService {
  constructor() {}

  private getStepName(type: PayrollCycleStep): string {
    switch (type) {
      case PayrollCycleStep.PAYROLL_DRAFT:
        return i18n.global.t("client.client-payroll-draft");
      case PayrollCycleStep.FINAL_PAYROLL:
        return i18n.global.t("client.client-final-payroll");
      case PayrollCycleStep.INVOICING:
        return i18n.global.t("client.client-invoicing");
      case PayrollCycleStep.PAYMENTS:
        return i18n.global.t("client.client-payments");
      default:
        return "";
    }
  }

  private getStatus(
    isCompleted: boolean,
    index: number,
    firstUncompletedIndex: number
  ): PayrolCycleStatus {
    if (isCompleted) {
      return PayrolCycleStatus.COMPLETED;
    }

    if (firstUncompletedIndex === index) {
      return PayrolCycleStatus.PENDING;
    }

    return PayrolCycleStatus.UNCOMPLETED;
  }

  public formatPayrollCycleSteps(
    payrollCycle: ClientPayrollCycleResponseDto,
    date: string
  ): PayrollCycleType[] {
    const firstUncompletedIndex = payrollCycle.steps.findIndex(
      (step) => !step.isCompleted
    );

    return payrollCycle.steps.map((step, index) => ({
      name: this.getStepName(step.type),
      status: this.getStatus(step.isCompleted, index, firstUncompletedIndex),
      dueDate: step.dueDate,
    }));
  }

  public getPayrollCycleFilesInTableFormat(payrollCycle: ClientPayrollCycleResponseDto) {
    const columnNames = [
      {
        name: i18n.global.t("client.client-payroll-stage"),
        width: "200",
      },
      {
        name: i18n.global.t("client.client-file-name"),
        width: "200",
      },
      {
        name: i18n.global.t("client.client-uploaded-date"),
        width: "200",
      },
      {
        name: i18n.global.t("client.client-action"),
        width: "100",
      },
    ];

    const fieldsNames = [
      "stage",
      "name",
      "uploadedAt",
      "action"
    ];

    const tableData: any[] = []

    payrollCycle.steps.forEach(step => {
      if (step.file) {
        tableData.push({
          stage: this.getStepName(step.type),
          name: step.file?.name,
          uploadedAt: formatDate(step.uploadedAt),
          downloadLink: step.file?.downloadLink,
          action: {
            type: "customComponent",
            component: "approveAction",
            actionTypes: ["download"],
          }
        })
      }
    })

    return {
      columnNames,
      fieldsNames,
      tableData
    }
  }
}

export default new ClientPayrollCycleFormatService();
