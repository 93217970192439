<template>
  <div class="navbar">
    <img :src="myLogoSrc" alt="aux-logo-text" class="logo" />
    <h3 class="nav-text">PAYROLL</h3>
    <template v-if="!isEmployee">
      <NavBarIcon
        :imgComponent="PayrollSVGComponent"
        title="Payroll Cycle"
        path="Payroll-cycle"
      />
      <NavBarIcon
        :imgComponent="OvertimeSVGComponent"
        title="Leaves"
        path="Leave"
      />
      <NavBarIcon
        :imgComponent="OvertimeSVGComponent"
        title="Overtimes"
        path="Overtime"
      />
      <NavBarIcon
        :imgComponent="CaseSVGComponent"
        title="Work Expenses"
        path="Work-expenses"
      />

      <h3 class="nav-text team">TEAM</h3>
      <NavBarIcon
        :imgComponent="UsersSVGComponent"
        title="Employees"
        path="Employees"
      />
      <NavBarIcon
        :imgComponent="DocumentSVGComponent"
        title="Documents"
        path="Documents"
      />
      <NavBarIcon
        :imgComponent="DocumentSVGComponent"
        title="Client Managers"
        path="ClientManagers"
        v-if="role === 'Client'"
      />

      <NavBarIcon
        :imgComponent="ContractSVGComponent"
        title="Contracts"
        path="Contracts"
        class="margin-64"
      />
      <NavBarIcon
        :imgComponent="InvoiceSVGComponent"
        title="Invoices"
        path="Invoices"
      />
      <NavBarIcon
        :imgComponent="ReportsSVGComponent"
        title="Reports"
        path="Reports"
      />
      <NavBarIcon
        :imgComponent="ScaleSVGComponent"
        title="Integrations"
        path="Integrations"
      />
      <NavBarIcon
        :imgComponent="DocumentSVGComponent"
        title="Notifications"
        path="Notifications"
        :notificationCounter="notificationCounter"
      />
    </template>
    <template v-else>
      <NavBarIcon
        :imgComponent="ProfileSVGComponent"
        title="Profile"
        path="EmployeesProfile"
        :params="{
          userId: employeeUuid,
        }"
      />
      <NavBarIcon
        :imgComponent="OvertimeSVGComponent"
        title="Overtime & Leave"
        path="EmployeeOvertime-Leave"
      />
      <NavBarIcon
        :imgComponent="CaseSVGComponent"
        title="Work Expenses"
        path="EmployeeExpensies"
      />
      <NavBarIcon
        :imgComponent="DocumentSVGComponent"
        title="Documents"
        path="EmployeeDocuments"
      />
      <NavBarIcon
        :imgComponent="DocumentSVGComponent"
        title="Notifications"
        path="EmployeeNotifications"
        :notificationCounter="notificationCounter"
      />
    </template>
    <NavBarUserSettings
      class="user-settings-link"
      :fullName="nickname"
      :imgSrc="userImg"
      :settings_url="setting"
      :isAdmin="isAdmin"
      :role="role"
    />
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";
import NavBarIcon from "../../components/NavBar/NavBarIcon.vue";
import NavBarUserSettings from "../../components/NavBar/NavBarUserSettings.vue";
import myLogoSrc from "../../assets/logo.svg";

import {
  OvertimeSVGComponent,
  ReportsSVGComponent,
  CaseSVGComponent,
  ScaleSVGComponent,
  DocumentSVGComponent,
  ContractSVGComponent,
  InvoiceSVGComponent,
  UsersSVGComponent,
  SettingSVGComponent,
  PayrollSVGComponent,
  ProfileSVGComponent,
} from "../../assets/svg";
import setting from "../../assets/Setting.svg";
import store from "@/store";

export default defineComponent({
  name: "NavBar",
  components: {
    NavBarIcon,
    NavBarUserSettings,
  },
  setup() {
    const nickname = computed(() => {
      return store.state.auth?.user?.fullName;
    });
    const userImg = computed(() => {
      return store.state.auth?.authUser?.picture;
    });

    const employeeUuid = computed(() => {
      return store.state?.auth?.user?.employee?.uuid;
    });
    const isAdmin = computed(() => {
      return store.getters.isAdmin;
    });

    const isEmployee = computed(() => {
      return store.getters.isEmployee;
    });

    const role = computed(() => {
      return store.getters.role;
    });
    const notificationCounter = computed(() => {
      return store.getters.notificationCounter;
    });

    return {
      nickname,
      userImg,
      myLogoSrc,
      setting,
      isAdmin,
      isEmployee,
      notificationCounter,
      OvertimeSVGComponent,
      ReportsSVGComponent,
      CaseSVGComponent,
      ScaleSVGComponent,
      DocumentSVGComponent,
      InvoiceSVGComponent,
      SettingSVGComponent,
      PayrollSVGComponent,
      ContractSVGComponent,
      UsersSVGComponent,
      ProfileSVGComponent,
      employeeUuid,
      role,
    };
  },
});
</script>

<style lang="scss" scoped>
.logo {
  margin-left: 8px;
}
.navbar {
  position: relative;
  width: 184px;
  height: 100vh;
  background-color: $base-white;
  padding-left: 16px;
  padding-right: 16px;

  .nav-text {
    margin-left: 8px;
    margin-top: 52px;
    margin-bottom: 8px;
    text-transform: uppercase;
    @include text;

    color: $base-gray;
  }

  .team {
    margin-top: 32px;
  }

  .margin-64 {
    margin-top: 64px;
  }

  .logo {
    height: 23px;
    margin-top: 16px;
  }

  .user-settings-link {
    position: absolute;
    bottom: 10px;
  }
}
</style>
