import { AxiosError, AxiosResponse } from "axios";
import { notify } from "@kyvg/vue3-notification";

import axiosReq from "@/utils/axiosReq";

class ClientLeaveApiService {
  constructor() {}

  public getLeaveRequests(page: number = 1, limit: number = 15): AxiosResponse {
    return axiosReq({
      url: `/clients/employees/leave-requests?page=${page}&limit=${limit}`,
      method: 'get',
    }).catch((err: AxiosError) => {
      notify({
        title: "Error load leave requests",
        text: err.message,
        type: "error",
      });
    }) 
  }

  public getEmployeeLeaveStatistics(
    uuid: string,
  ): AxiosResponse {
    return axiosReq({
      url: `/employees/${uuid}/leave-requests/statistics`,
      method: "get",
    }).catch((err: AxiosError) => {
      notify({
        title: "Error load",
        text: err.message,
        type: "error",
      });
    });
  }

  public approveEmployeeLeaveRequest(
    uuid: string,
    leaveUuid: string,
    type: string,
    data: { comment: string }
  ): AxiosResponse {
    return axiosReq({
      url: `/employees/${uuid}/leave-requests/${leaveUuid}/${type}`,
      method: "post",
      data,
    }).catch((err: AxiosError) => {
      if (err.response?.status === 409) {
        notify({
          title: `Error ${type} leave request`,
          text: err.response.data.message,
          type: "error",
        });
        return;
      }
      Object.values(
        (
          err.response as {
            data: {
              message: [];
            };
          }
        ).data.message
      )
        .flat()
        .forEach((text: string) => {
          notify({
            title: `Error ${type} leave request`,
            text,
            type: "error",
          });
        });
    });
  }
}

export default new ClientLeaveApiService();
