<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.3"
      d="M2.70825 5.00008C2.70825 2.81395 4.48046 1.04175 6.66659 1.04175H13.3333C15.5194 1.04175 17.2916 2.81395 17.2916 5.00008V15.0001C17.2916 17.1862 15.5194 18.9584 13.3333 18.9584H6.66659C4.48046 18.9584 2.70825 17.1862 2.70825 15.0001V5.00008Z"
      :fill="color || '#0A1E46'"
    />
    <path
      d="M6.6665 5.8335H13.3332"
      :stroke="color || '#0A1E46'"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M6.6665 10.0002H13.3332"
      :stroke="color || '#0A1E46'"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M6.6665 14.1667H9.99984"
      :stroke="color || '#0A1E46'"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  name: "ContractSVGComponent",
  props: {
    color: {
      type: String,
      required: false,
    },
  },
};
</script>
