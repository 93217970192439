<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.3">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.8749 1.04703C11.8233 1.04352 11.7714 1.04175 11.7193 1.04175H6.66658C4.48046 1.04175 2.70825 2.81395 2.70825 5.00008V15.0001C2.70825 17.1862 4.48046 18.9584 6.66658 18.9584H13.3333C15.5194 18.9584 17.2916 17.1862 17.2916 15.0001V7.29175H14.1666C12.9009 7.29175 11.8749 6.26573 11.8749 5.00008V1.04703ZM6.04158 10.0001C6.04158 9.6549 6.32141 9.37508 6.66658 9.37508H13.3333C13.6784 9.37508 13.9583 9.6549 13.9583 10.0001C13.9583 10.3453 13.6784 10.6251 13.3333 10.6251H6.66658C6.32141 10.6251 6.04158 10.3453 6.04158 10.0001ZM6.66658 13.5417C6.32141 13.5417 6.04158 13.8216 6.04158 14.1667C6.04158 14.5119 6.32141 14.7917 6.66658 14.7917H9.99992C10.3451 14.7917 10.6249 14.5119 10.6249 14.1667C10.6249 13.8216 10.3451 13.5417 9.99992 13.5417H6.66658Z"
        :fill="color || '#0A1E46'"
      />
      <path
        d="M16.9345 6.04175C16.8819 5.95891 16.8238 5.87916 16.7604 5.80308L13.4798 1.86633C13.3732 1.73842 13.2541 1.62372 13.1249 1.52343V5.00008C13.1249 5.57538 13.5913 6.04175 14.1666 6.04175H16.9345Z"
        :fill="color || '#0A1E46'"
      />
    </g>
    <path
      d="M6.6665 10H13.3332"
      :stroke="color || '#0A1E46'"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M6.6665 14.1667H9.99984"
      :stroke="color || '#0A1E46'"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  name: "DocumentSVGComponent",
  props: {
    color: {
      type: String,
      required: false,
    },
  },
};
</script>
