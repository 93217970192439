import { EmployeeDocumentType } from './enums';
import { IsEnum } from 'class-validator';

export class EmployeeDocumentRequestDto {
  file: string;

  @IsEnum(EmployeeDocumentType)
  type: EmployeeDocumentType;

  expiryDate?: Date;
}
