import { PaginationQueryRequestDto } from '../../../core';
import { EmployeeOvertimeRequestStatus, EmployeeOvertimeRequestType } from '../../enums';
import { IsEnum, IsOptional, IsString } from 'class-validator';

export class EmployeeOvertimeRequestPaginationQueryRequestDto extends PaginationQueryRequestDto {
  @IsOptional()
  @IsString()
  client?: string;

  @IsOptional()
  @IsString()
  employee?: string;

  @IsOptional()
  @IsEnum(EmployeeOvertimeRequestType, { each: true })
  types?: EmployeeOvertimeRequestType | EmployeeOvertimeRequestType[];

  @IsOptional()
  @IsEnum(EmployeeOvertimeRequestStatus, { each: true })
  statuses?: EmployeeOvertimeRequestStatus | EmployeeOvertimeRequestStatus[];
}
