<template>
  <div class="login-container">
    <SuccessPage
      :title="$t('registration.registration-sent-confirmation-email-user')"
      :description="
        $t('registration.registration-sent-confirmation-email-description-user')
      "
      :image="send"
      :backgroundImage="vector"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import SuccessPage from "../../../components/SuccessPage/SuccessPage.vue";

import send from "../../../assets/Send.svg";
import vector from "../../../assets/Vector.svg";

export default defineComponent({
  name: "SignUp",
  components: {
    SuccessPage,
  },
  setup() {
    return {
      send,
      vector,
    };
  },
});
</script>

<style lang="scss" scoped>
.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}
</style>
