import {
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw,
} from "vue-router";
import EmployeesPage from "@/views/client/Employees/EmployeesPage.vue";
import UserSettings from "@/views/client/Client/UserSettings.vue";
import OvertimeEmployeeLeaves from "@/views/employee/OvertimeLeaves/OvertimeLeaves.vue";
import EmployeeDocuments from "@/views/employee/Documents/Documents.vue";
import EmployeeExpense from "@/views/employee/Expense/Expense.vue";
import NotificationsView from "@/views/shared/NotificationsView/NotificationsView.vue";
import store from "../store";

export const employeeRoutes: Array<RouteRecordRaw> = [
  {
    path: "/account/:userId",
    name: "EmployeesProfile",
    component: EmployeesPage,
    meta: { requiresEmployee: true },
  },
  {
    path: "/employee-documents",
    name: "EmployeeDocuments",
    component: EmployeeDocuments,
    meta: { requiresEmployee: true },
  },
  {
    path: "/employee-settings",
    name: "EmployeeSettingsPage",
    component: UserSettings,
    meta: { requiresEmployee: true },
  },
  {
    path: "/employee-work-expenses",
    name: "EmployeeExpensies",
    component: EmployeeExpense,
    meta: { requiresEmployee: true },
  },
  {
    path: "/employee-overtime-leave",
    name: "EmployeeOvertime-Leave",
    component: OvertimeEmployeeLeaves,
    meta: { requiresEmployee: true },
  },
  {
    path: "/employee/notifications",
    name: "EmployeeNotifications",
    component: NotificationsView,
    meta: { requiresEmployee: true },
  },
];

export const employeeGuard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  if (to.matched.some((record) => !record.meta.guest)) {
    if (to.matched[0].meta.requiresEmployee && !store.getters.isEmployee) {
      next("/employees");
      return;
    }
    next();
  } else {
    next();
  }
};
