import { EmployeeDocumentRequestType } from '../enums';
import { IsEnum, IsOptional, IsString, MaxLength } from 'class-validator';

export class EmployeeDocumentCreateRequestRequestDto {
  @IsEnum(EmployeeDocumentRequestType)
  type: EmployeeDocumentRequestType;

  @IsOptional()
  @IsString()
  @MaxLength(500)
  comment?: string;
}
