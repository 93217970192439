<template>
  <svg
    v-if="className !== 'disabled'"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.1018 12.8635L10.1018 2.82935"
      stroke="#0E3EBA"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.5318 10.4238L10.1018 12.8638L7.6718 10.4238"
      stroke="#0E3EBA"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.9625 6.77344H14.74C16.4358 6.77344 17.81 8.1476 17.81 9.84427V13.9143C17.81 15.6059 16.4392 16.9768 14.7475 16.9768H5.46416C3.76833 16.9768 2.39333 15.6018 2.39333 13.9059L2.39333 9.8351C2.39333 8.14427 3.765 6.77344 5.45583 6.77344L6.24083 6.77344"
      stroke="#0E3EBA"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
  <svg
    v-else
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.3">
      <path
        d="M10.1019 12.8633L10.1019 2.8291"
        stroke="#666F88"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.5322 10.4233L10.1022 12.8633L7.67223 10.4233"
        stroke="#666F88"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.9626 6.77295H14.7401C16.4359 6.77295 17.8101 8.14712 17.8101 9.84378V13.9138C17.8101 15.6054 16.4392 16.9763 14.7476 16.9763H5.46423C3.76839 16.9763 2.39339 15.6013 2.39339 13.9054L2.39339 9.83462C2.39339 8.14378 3.76506 6.77295 5.45589 6.77295L6.24089 6.77295"
        stroke="#666F88"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "DownloadSVGComponent",
  props: {
    color: {
      type: String,
      required: false,
    },
    className: {
      type: String,
      required: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.disabled {
  path {
    fill: #666f88;
  }
}
</style>