import { PaginationQueryRequestDto } from '../../core';
import { EmployeeLeaveRequestStatus, EmployeeLeaveRequestType } from '../enums';
import { IsEnum, IsOptional, IsString } from 'class-validator';

export class EmployeeLeaveRequestPaginationQueryRequestDto extends PaginationQueryRequestDto {
  @IsOptional()
  @IsString()
  client?: string;

  @IsOptional()
  @IsString()
  employee?: string;

  @IsOptional()
  @IsEnum(EmployeeLeaveRequestType, { each: true })
  types?: EmployeeLeaveRequestType | EmployeeLeaveRequestType[];

  @IsOptional()
  @IsEnum(EmployeeLeaveRequestStatus, { each: true })
  statuses?: EmployeeLeaveRequestStatus | EmployeeLeaveRequestStatus[];
}
