<template>
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Iconly/Light-Outline/Profile">
      <g id="Profile">
        <g id="Group 3">
          <mask
            id="mask0_727_20825"
            style="mask-type:alpha"
            maskUnits="userSpaceOnUse"
            x="10"
            y="36"
            width="40"
            height="19"
          >
            <path
              id="Clip 2"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10 36.2402H49.5997V54.6752H10V36.2402Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0_727_20825)">
            <path
              id="Fill 1"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M29.8023 39.9902C19.1498 39.9902 13.7498 41.8202 13.7498 45.4327C13.7498 49.0777 19.1498 50.9252 29.8023 50.9252C40.4523 50.9252 45.8498 49.0952 45.8498 45.4827C45.8498 41.8377 40.4523 39.9902 29.8023 39.9902ZM29.8023 54.6752C24.9048 54.6752 9.99976 54.6752 9.99976 45.4327C9.99976 37.1927 21.3023 36.2402 29.8023 36.2402C34.6998 36.2402 49.5998 36.2402 49.5998 45.4827C49.5998 53.7227 38.2998 54.6752 29.8023 54.6752Z"
              fill="#CACBD5"
            />
          </g>
        </g>
        <g id="Group 6">
          <mask
            id="mask1_727_20825"
            style="mask-type:alpha"
            maskUnits="userSpaceOnUse"
            x="16"
            y="5"
            width="28"
            height="27"
          >
            <path
              id="Clip 5"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M16.5247 5.00024H43.0747V31.5467H16.5247V5.00024Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask1_727_20825)">
            <path
              id="Fill 4"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M29.8022 8.56927C24.4497 8.56927 20.0947 12.9218 20.0947 18.2743C20.0772 23.6093 24.3997 27.9593 29.7297 27.9793L29.8022 29.7643V27.9793C35.1522 27.9793 39.5047 23.6243 39.5047 18.2743C39.5047 12.9218 35.1522 8.56927 29.8022 8.56927ZM29.8022 31.5468H29.7222C22.4172 31.5243 16.4997 25.5668 16.5247 18.2668C16.5247 10.9543 22.4797 4.99927 29.8022 4.99927C37.1222 4.99927 43.0747 10.9543 43.0747 18.2743C43.0747 25.5943 37.1222 31.5468 29.8022 31.5468Z"
              fill="#CACBD5"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "ProfileSVGComponent",
  props: {
    color: {
      type: String,
      required: false,
    },
  },
};
</script>
