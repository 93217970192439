<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.3"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.5 2.29175C2.15482 2.29175 1.875 2.57157 1.875 2.91675C1.875 3.26193 2.15482 3.54175 2.5 3.54175H3.54167V16.2214C3.54167 17.4323 4.93201 18.1157 5.89075 17.3761L6.99978 16.5206C7.06953 16.4668 7.16559 16.4626 7.23969 16.5103L8.76076 17.4881C9.51566 17.9734 10.4843 17.9734 11.2392 17.4881L12.7603 16.5103C12.8344 16.4626 12.9305 16.4668 13.0002 16.5206L14.1092 17.3761C15.068 18.1157 16.4583 17.4323 16.4583 16.2214V3.54175H17.5C17.8452 3.54175 18.125 3.26193 18.125 2.91675C18.125 2.57157 17.8452 2.29175 17.5 2.29175H2.5ZM6.875 7.91675C6.875 7.57157 7.15482 7.29175 7.5 7.29175H10C10.3452 7.29175 10.625 7.57157 10.625 7.91675C10.625 8.26193 10.3452 8.54175 10 8.54175H7.5C7.15482 8.54175 6.875 8.26193 6.875 7.91675ZM7.5 10.6251C7.15482 10.6251 6.875 10.9049 6.875 11.2501C6.875 11.5953 7.15482 11.8751 7.5 11.8751H10C10.3452 11.8751 10.625 11.5953 10.625 11.2501C10.625 10.9049 10.3452 10.6251 10 10.6251H7.5ZM11.875 7.91675C11.875 7.57157 12.1548 7.29175 12.5 7.29175H12.9167C13.2618 7.29175 13.5417 7.57157 13.5417 7.91675C13.5417 8.26193 13.2618 8.54175 12.9167 8.54175H12.5C12.1548 8.54175 11.875 8.26193 11.875 7.91675ZM12.5 10.6251C12.1548 10.6251 11.875 10.9049 11.875 11.2501C11.875 11.5953 12.1548 11.8751 12.5 11.8751H12.9167C13.2618 11.8751 13.5417 11.5953 13.5417 11.2501C13.5417 10.9049 13.2618 10.6251 12.9167 10.6251H12.5Z"
      :fill="color || '#0A1E46'"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.5 2.29175C2.15482 2.29175 1.875 2.57157 1.875 2.91675C1.875 3.26193 2.15482 3.54175 2.5 3.54175H3.54167H16.4583H17.5C17.8452 3.54175 18.125 3.26193 18.125 2.91675C18.125 2.57157 17.8452 2.29175 17.5 2.29175H2.5Z"
      :fill="color || '#0A1E46'"
    />
  </svg>
</template>

<script>
export default {
  name: "InvoiceSVGComponent",
  props: {
    color: {
      type: String,
      required: false,
    },
  },
};
</script>
