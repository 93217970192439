import { createStore } from "vuex";

import auth from "./modules/auth";
import base from "./modules/base";
import employees from "./modules/employees";

export default createStore({
  modules: {
    auth,
    employees,
    base
  },
  // plugins: [createPersistedState()],
});
