<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_2142_43389"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="1"
      y="1"
      width="14"
      height="14"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.3335 1.33398H14.9999V15.0003H1.3335V1.33398Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_2142_43389)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.90622 3.37475C2.90755 3.37475 2.87422 3.40942 2.83222 3.45075C2.67089 3.60742 2.33689 3.93342 2.33355 4.61542C2.32822 5.56942 2.95555 7.34075 5.97622 10.3608C8.98289 13.3661 10.7516 14.0008 11.7076 14.0008H11.7216C12.4036 13.9974 12.7289 13.6628 12.8862 13.5021C12.9342 13.4528 12.9729 13.4168 13.0002 13.3941C13.6642 12.7261 14.0042 12.2288 14.0009 11.9101C13.9962 11.5848 13.5916 11.2001 13.0322 10.6681C12.8542 10.4988 12.6609 10.3148 12.4569 10.1108C11.9282 9.58342 11.6662 9.67342 11.0902 9.87609C10.2936 10.1554 9.20022 10.5354 7.50155 8.83609C5.80022 7.13609 6.18089 6.04409 6.45955 5.24742C6.66089 4.67142 6.75289 4.40875 6.22355 3.87942C6.01622 3.67275 5.83022 3.47675 5.65889 3.29675C5.13022 2.74075 4.74889 2.33875 4.42555 2.33409H4.42022C4.10089 2.33409 3.60489 2.67542 2.90289 3.37742C2.90489 3.37542 2.90555 3.37475 2.90622 3.37475ZM11.7082 15.0008C10.0469 15.0008 7.88089 13.6781 5.26955 11.0681C2.64822 8.44742 1.32355 6.27475 1.33355 4.61009C1.33955 3.51009 1.91622 2.94675 2.13289 2.73542C2.14422 2.72142 2.18289 2.68342 2.19622 2.67009C3.15222 1.71342 3.79689 1.32675 4.43822 1.33409C5.18289 1.34409 5.71289 1.90142 6.38355 2.60742C6.54955 2.78209 6.72955 2.97209 6.93022 3.17209C7.90355 4.14542 7.62622 4.93942 7.40355 5.57675C7.16089 6.27209 6.95089 6.87209 8.20822 8.12942C9.46688 9.38675 10.0669 9.17675 10.7596 8.93209C11.3976 8.70942 12.1896 8.43075 13.1642 9.40409C13.3616 9.60142 13.5489 9.77942 13.7216 9.94409C14.4309 10.6181 14.9909 11.1508 15.0002 11.8974C15.0082 12.5341 14.6216 13.1828 13.6669 14.1381L13.2442 13.8514L13.6016 14.2008C13.3902 14.4174 12.8276 14.9948 11.7269 15.0008H11.7082Z"
        fill="#666F88"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "CallSVGComponent",
  props: {
    color: {
      type: String,
      required: false,
    },
  },
};
</script>
