import { ClientResponseDto, UserResponseDto } from "@/dto/src";
import { Commit } from "vuex";
import ClientService from "@/services/ClientService";

export type Auth0ClientState = {
  email: string;
  email_verified: boolean;
  "http://www.auxillium.com/roles": string[];
  name: string;
  nickname: string;
  picture: string;
  sub: string;
  updated_at: string;
};
export type AuthState = {
  user: ClientResponseDto | UserResponseDto;
  role: string;
  authUser: Auth0ClientState;
  availableAnnualLeaves: number;
};
const state: AuthState = {
  role: "",
  user: {} as any,
  authUser: {} as Auth0ClientState,
  availableAnnualLeaves: 0
};

const getters = {
  isAuthenticated: (state: AuthState) => !!state?.authUser?.email,
  isAdmin: (state: AuthState) => ClientService.isAdmin(state?.authUser),
  // isEmployee: (state: AuthState) => true,
  // consider that it can be admin on behalf of employee
  isEmployee: (state: AuthState) => state?.role === "Employee",
  StateUser: (state: AuthState) => state.user,
  role: (state: AuthState) => state.role,
  stateAuthUser: (state: AuthState) => state.authUser,
  availableAnnualLeaves: (state: AuthState) => state.availableAnnualLeaves,
};

const actions = {
  async Register({ dispatch }: any, data: any) {
    await dispatch("LogIn", data);
  },

  async LogIn({ commit }: { commit: Commit }, user: any) {
    await commit("setUser", user);
  },

  async LogOut({ commit }: { commit: Commit }) {
    let user = null;
    commit("logout", user);
  },
};

const mutations = {
  setUser(state: AuthState, user: any) {
    state.user = user;
  },
  setRole(state: AuthState, role: string) {
    state.role = role;
  },
  setAuthUser(state: AuthState, user: any) {
    state.authUser = user;
  },
  logout(state: AuthState, user: any) {
    state.user = user;
  },
  setAvailableAnnualLeaves(state: AuthState, leaves: number) {
    state.availableAnnualLeaves = leaves;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
