import BaseDataService from "@/services/BaseDataService";
import ClientService from "@/services/ClientService";
import store from "@/store";
import { setToken } from "@/utils/auth";
import createAuth0Client, {
  Auth0Client,
  GetIdTokenClaimsOptions,
  GetTokenSilentlyOptions,
  GetTokenWithPopupOptions,
  LogoutOptions,
  RedirectLoginOptions,
  User,
} from "@auth0/auth0-spa-js";
import { App, Plugin, computed, reactive, watchEffect } from "vue";
import { NavigationGuard } from "vue-router";

let client: Auth0Client;

interface Auth0PluginState {
  loading: boolean;
  isAuthenticated: boolean;
  user: User | undefined;
  roles: string[];
  popupOpen: boolean;
  error: any;
}

const state = reactive<Auth0PluginState>({
  loading: true,
  isAuthenticated: false,
  user: {},
  roles: [],
  popupOpen: false,
  error: null,
});

async function handleRedirectCallback() {
  state.loading = true;

  try {
    await client.handleRedirectCallback();
    state.user = await client.getUser();
    state.isAuthenticated = true;
    state.error = null;
  } catch (e) {
    state.error = e;
  } finally {
    state.loading = false;
  }
}

function loginWithRedirect(o: RedirectLoginOptions) {
  return client.loginWithRedirect(o);
}

function getIdTokenClaims(o: GetIdTokenClaimsOptions) {
  return client.getIdTokenClaims(o);
}

function getTokenSilently(o: GetTokenSilentlyOptions) {
  return client.getTokenSilently(o);
}

function getTokenWithPopup(o: GetTokenWithPopupOptions) {
  return client.getTokenWithPopup(o);
}

function logout(o: LogoutOptions) {
  return client.logout(o);
}

const authPlugin = {
  isAuthenticated: computed(() => state.isAuthenticated),
  loading: computed(() => state.loading),
  user: computed(() => state.user),
  roles: computed(() => state.roles),
  getIdTokenClaims,
  getTokenSilently,
  getTokenWithPopup,
  handleRedirectCallback,
  loginWithRedirect,
  logout,
};

const routeGuard: NavigationGuard = (to: any, from: any, next: any) => {
  const { isAuthenticated, loading, loginWithRedirect } = authPlugin;

  const verify = async () => {
    // If the user is authenticated, continue with the route
    if (isAuthenticated.value) {
      return next();
    }

    // Otherwise, log in
    await loginWithRedirect({ appState: { targetUrl: to.fullPath } });
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!loading.value) {
    return verify();
  }

  // Watch for the loading property to change before we check isAuthenticated
  watchEffect(() => {
    if (!loading.value) {
      return verify();
    }
  });
};

interface Auth0PluginOptions {
  domain: string;
  clientId: string;
  redirectUri: string;

  onRedirectCallback(appState: any): void;
}

async function init(options: Auth0PluginOptions): Promise<Plugin> {
  client = await createAuth0Client({
    domain: options.domain,
    client_id: options.clientId,
    redirect_uri: options.redirectUri,
  });

  try {
    // If the user is returning to the app after authentication
    if (
      window.location.search.includes("code=") &&
      window.location.search.includes("state=")
    ) {
      // handle the redirect and retrieve tokens
      const { appState } = await client.handleRedirectCallback();

      // Notify subscribers that the redirect callback has happened, passing the appState
      // (useful for retrieving any pre-authentication state)
      options.onRedirectCallback(appState);
    }

    if (window.location.search.includes("error=")) {
      const urlParams = new URLSearchParams(window.location.search);

      logout({
        returnTo: `${window.location.origin}/login?isEmailError=active`,
      });
    }
  } catch (e) {
    state.error = e;
  } finally {
    // Initialize our internal authentication state
    state.isAuthenticated = await client.isAuthenticated();
    state.user = await client.getUser();
    const user = await client.getIdTokenClaims();
    if (state.user) {
      state.roles = state.user["http://www.auxillium.com/roles"];
    }

    if (user?.__raw) {
      setToken(user?.__raw);
      try {
        const roleRes = await ClientService.loadCurrentRole();
        if (!roleRes) {
          logout({
            returnTo: window.location.origin,
          });
        }

        const role = roleRes.data.payload;
        const res = await ClientService.loadEmployeeInfo();

        if (!res) {
          state.roles &&
            state.roles[0] === "Admin" &&
            alert('Sorry, you are an admin. Use "Login As"');
          logout({
            returnTo: window.location.origin,
          });
        }
        store.commit("setRole", role);
        store.commit("setUser", res.data.payload);

        if (res.data.payload?.employee) {
          store.commit(
            "setAvailableAnnualLeaves",
            res.data.payload.employee.totalAnnualLeaveCount -
              res.data.payload.employee.requestedAnnualLeaveCount
          );
        }
      } catch (err: any) {}

      try {
        await BaseDataService.loadFeatureFlags();
      } catch (err: any) {
        console.log("err loadFeatureFlags", err);
      }
    }

    state.loading = false;
  }

  return {
    install: (app: App) => {
      app.provide("Auth", authPlugin);
    },
  };
}

interface Auth0Plugin {
  init(options: Auth0PluginOptions): Promise<Plugin>;
  routeGuard: NavigationGuard;
}

export const Auth0: Auth0Plugin = {
  init,
  routeGuard,
};
