import { NotificationPaginatedMetaDto } from "@/dto/src/notification/notification-paginated.dto";
import store from "@/store";
import axiosReq from "@/utils/axiosReq";
import { notify } from "@kyvg/vue3-notification";
import { AxiosError, AxiosResponse } from "axios";
import { Notification } from "@/services/SocketService/types";

class BaseDataService {
  constructor() {}

  loadFeatureFlags() {
    return axiosReq({
      url: "/features",
      method: "get",
    })
      .then((res: AxiosResponse) => {
        store.commit("setFeatureFlags", res.data.payload);
      })
      .catch((err: AxiosError) => {
        notify({
          title: "Error load Countries",
          text: err.message,
          type: "error",
        });
      });
  }

  loadCountries() {
    return axiosReq({
      url: "/countries",
      method: "get",
    })
      .then((res: AxiosResponse) => {
        store.commit("setCountry", res.data.payload);
      })
      .catch((err: AxiosError) => {
        notify({
          title: "Error load Countries",
          text: err.message,
          type: "error",
        });
      });
  }

  loadSalaryTypes() {
    axiosReq({
      url: "/salary-types",
      method: "get",
    })
      .then((res: AxiosResponse) => {
        store.commit("setSalaryType", res.data.payload);
      })
      .catch((err: AxiosError) => {
        notify({
          title: "Error load Salary Type",
          text: err.message,
          type: "error",
        });
      });
  }

  loadCurrencies() {
    axiosReq({
      url: "/currencies",
      method: "get",
    })
      .then((res: AxiosResponse) => {
        store.commit("setCurrency", res.data.payload);
      })
      .catch((err: AxiosError) => {
        notify({
          title: "Error load Currencies",
          text: err.message,
          type: "error",
        });
      });
  }

  async loadRates(currency: string) {
    const res = await axiosReq({
      url: `/currencies/rates?base=${currency}`,
      method: "get",
    }).catch((err: AxiosError) => {
      notify({
        title: "Error load Currencies",
        text: err.message,
        type: "error",
      });
    });

    store.commit("setRates", res.data.rates);
    return res.data.rates;
  }

  async uploadDocument(type: string, fileName: string, file: File) {
    const res = await axiosReq({
      url: `/employees/files/${type}/upload/${fileName}`,
      method: "get",
    });

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "image/png");

    const requestOptions: any = {
      method: "PUT",
      headers: myHeaders,
      body: file,
      redirect: "follow",
    };

    const uploadRes = await fetch(res.data.payload.uploadLink, requestOptions);
    return res.data.payload.key;
  }

  async uploadDocumentForClient(
    uuid: string,
    type: string,
    fileName: string,
    file: File
  ) {
    const res = await axiosReq({
      url: `/employees/${uuid}/files/${type}/upload/${fileName}`,
      method: "get",
    });

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "image/png");

    const requestOptions: any = {
      method: "PUT",
      headers: myHeaders,
      body: file,
      redirect: "follow",
    };

    const uploadRes = await fetch(res.data.payload.uploadLink, requestOptions);
    return res.data.payload.key;
  }

  public getNotifications(
    page = 1,
    limit = 15
  ): AxiosResponse<{
    meta: NotificationPaginatedMetaDto;
    payload: Notification[];
  }> {
    return axiosReq({
      url: `/notifications?page=${page}&limit=${limit}`,
      method: "get",
    });
  }

  public resolveNotification(uuid: string): AxiosResponse {
    return axiosReq({
      url: `/notifications/${uuid}/resolve`,
      method: "post",
    });
  }
}

export default new BaseDataService();
