<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      d="M32.68 3.99951H15.34C8.56 3.99951 4 8.75951 4 15.8395V32.1795C4 39.2395 8.56 43.9995 15.34 43.9995H32.68C39.46 43.9995 44 39.2395 44 32.1795V15.8395C44 8.75951 39.46 3.99951 32.68 3.99951Z"
      fill="#FFC456"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.2493 16.3787C22.2493 17.3427 23.0313 18.1287 23.9893 18.1287C24.9753 18.1287 25.7593 17.3427 25.7593 16.3787C25.7593 15.4147 24.9753 14.6287 24.0093 14.6287C23.0393 14.6287 22.2493 15.4147 22.2493 16.3787ZM25.7393 22.7243C25.7393 21.7603 24.9533 20.9743 23.9893 20.9743C23.0253 20.9743 22.2393 21.7603 22.2393 22.7243V31.5643C22.2393 32.5283 23.0253 33.3143 23.9893 33.3143C24.9533 33.3143 25.7393 32.5283 25.7393 31.5643V22.7243Z"
      fill="#FFC456"
    />
  </svg>
</template>

<script>
export default {
  name: "InfoPendingSVGComponent",
  props: {
    color: {
      type: String,
      required: false,
    },
  },
};
</script>
