import { IsEnum, IsNumber, IsOptional, Max, Min } from 'class-validator';
import { EmployeeBillingMethod } from './enums';

export class EmployeeInsuranceDto {
  @IsOptional()
  @IsEnum(EmployeeBillingMethod)
  insuranceBillingMethod?: EmployeeBillingMethod;

  @IsOptional()
  @IsNumber()
  @Min(0)
  insuranceCost?: number;

  @IsOptional()
  @IsNumber()
  @Min(0)
  insurancePaidAmount?: number;

  @IsNumber()
  @Min(1)
  @Max(12)
  @IsOptional()
  insuranceRecoveryPeriod?: number;

  @IsOptional()
  @IsNumber()
  @Min(0)
  insuranceChargedToClient?: number;
}
