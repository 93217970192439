<template>
  <div class="divider" />
</template>

<style lang="scss" scoped>
.divider {
  height: 1px;
  width: 100%;
  background: $dark-blue;
  opacity: 0.08;
}
</style>
