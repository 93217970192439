import { IsNumber, Max, Min, IsOptional, IsEnum } from 'class-validator';
import { EmployeeBillingMethod } from './enums';

export class EmployeeVisaDto {
  @IsOptional()
  @IsEnum(EmployeeBillingMethod)
  visaBillingMethod?: EmployeeBillingMethod;

  @IsOptional()
  @IsNumber()
  @Min(0)
  visaCost?: number;

  @IsOptional()
  @IsNumber()
  @Min(0)
  visaChargedToClient?: number;

  @IsOptional()
  @IsNumber()
  @Min(0)
  visaPaidAmount?: number;

  @IsNumber()
  @Min(1)
  @Max(12)
  @IsOptional()
  visaRecoveryPeriod?: number;
}
