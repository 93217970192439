import { IsBoolean, IsEnum, IsNumber, IsOptional, Min } from 'class-validator';
import { EmployeeLeaveType } from './enums';

export class EmployeeLeaveDto {
  @IsOptional()
  @IsEnum(EmployeeLeaveType)
  annualLeaveType?: EmployeeLeaveType;

  @IsNumber()
  @IsOptional()
  @Min(0)
  annualLeaveTotal?: number;

  @IsNumber()
  @IsOptional()
  @Min(0)
  annualLeave?: number;

  @IsNumber()
  @IsOptional()
  @Min(0)
  sickDays?: number;

  @IsNumber()
  @IsOptional()
  @Min(0)
  maternityLeave?: number;

  @IsNumber()
  @IsOptional()
  @Min(0)
  unpaidLeaveDays?: number;

  @IsOptional()
  @IsNumber()
  @Min(0)
  monthlyLeaveSalary?: number;

  @IsOptional()
  @IsNumber()
  @Min(0)
  previousYearCarryOverDays?: number;
}
