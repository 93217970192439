import { ClientPayrollCycleRequestDto, ClientPayrollStepRequestDto } from './request';
import { PayrollCycleStep } from './enums';

export const defaultPayrollCycleSteps: ClientPayrollStepRequestDto[] = [
  {
    type: PayrollCycleStep.PAYROLL_DRAFT,
    isCompleted: false,
    dueDate: '18th',
  },
  {
    type: PayrollCycleStep.FINAL_PAYROLL,
    isCompleted: false,
    dueDate: '22th',
  },
  {
    type: PayrollCycleStep.INVOICING,
    isCompleted: false,
    dueDate: '25th',
  },
  {
    type: PayrollCycleStep.PAYMENTS,
    isCompleted: false,
    dueDate: '28th',
  },
];

export const defaultPayrollCycle: ClientPayrollCycleRequestDto = {
  steps: defaultPayrollCycleSteps,
  date: new Date(),
};
