<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.3"
      d="M16.0812 6.17052V14.2459M14.2459 4.33521H6.17052M14.2459 16.0812H6.17052M4.33521 6.17052V14.2459"
      :stroke="color || '#0A1E46'"
      stroke-width="1.5"
    />
    <ellipse
      cx="16.0814"
      cy="4.33532"
      rx="1.83532"
      ry="1.83532"
      :fill="color || '#0A1E46'"
      :stroke="color || '#0A1E46'"
      stroke-width="1.5"
    />
    <circle
      cx="4.33532"
      cy="4.33532"
      r="1.83532"
      :fill="color || '#0A1E46'"
      :stroke="color || '#0A1E46'"
      stroke-width="1.5"
    />
    <circle
      cx="16.0814"
      cy="16.0814"
      r="1.83532"
      :fill="color || '#0A1E46'"
      :stroke="color || '#0A1E46'"
      stroke-width="1.5"
    />
    <ellipse
      cx="4.33532"
      cy="16.0814"
      rx="1.83532"
      ry="1.83532"
      :fill="color || '#0A1E46'"
      :stroke="color || '#0A1E46'"
      stroke-width="1.5"
    />
  </svg>
</template>

<script>
export default {
  name: "ScaleSVGComponent",
  props: {
    color: {
      type: String,
      required: false,
    },
  },
};
</script>
