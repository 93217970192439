<template>
  <svg
    v-if="className !== 'disabled'"
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.9833 7.20222L9.91666 17.2362"
      stroke="#0E3EBA"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.53711 9.62573L9.98326 7.20193L12.397 9.65801"
      stroke="#0E3EBA"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.08228 13.2665L5.3048 13.2614C3.609 13.2501 2.24399 11.8668 2.25526 10.1702L2.28229 6.10029C2.29353 4.40866 3.67343 3.04696 5.36506 3.0582L14.6482 3.11985C16.344 3.13112 17.7098 4.51522 17.6986 6.21102L17.6715 10.2818C17.6603 11.9726 16.2796 13.3342 14.5888 13.323L13.8038 13.3178"
      stroke="#0E3EBA"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>

  <svg
    v-else
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.9833 7.20222L9.91666 17.2362"
      stroke="#CACBD5"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.53711 9.62573L9.98326 7.20193L12.397 9.65801"
      stroke="#CACBD5"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.08228 13.2665L5.3048 13.2614C3.609 13.2501 2.24399 11.8668 2.25526 10.1702L2.28229 6.10029C2.29353 4.40866 3.67343 3.04696 5.36506 3.0582L14.6482 3.11985C16.344 3.13112 17.7098 4.51522 17.6986 6.21102L17.6715 10.2818C17.6603 11.9726 16.2796 13.3342 14.5888 13.323L13.8038 13.3178"
      stroke="#CACBD5"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "UploadSVGComponent",
  props: {
    color: {
      type: String,
      required: false,
    },
    className: {
      type: String,
      required: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.disabled {
  path {
    fill: #666f88;
  }
}
</style>