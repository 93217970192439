import axiosReq from "@/utils/axiosReq";
import { AxiosError, AxiosResponse } from "axios";

import { notify } from "@kyvg/vue3-notification";

class ClientOvertimeApiService {
  constructor() {}

  public getOvertimeRequests(page = 1): AxiosResponse {
    return axiosReq({
      url: `/clients/employees/overtime-requests?limit=15&page=${page}`,
      method: "get",
    }).catch((err: AxiosError) => {
      notify({
        title: "Error load",
        text: err.message,
        type: "error",
      });
    });
  }

  public approveEmployeeOvertime(
    uuid: string,
    doc_uuid: string,
    type: string,
    data: { comment: string }
  ): AxiosResponse {
    return axiosReq({
      url: `/employees/${uuid}/overtime-requests/${doc_uuid}/${type}`,
      method: "post",
      data,
    }).catch((err: AxiosError) => {
      if (err.response?.status === 409) {
        notify({
          title: "Error create",
          text: err.response.data.message,
          type: "error",
        });
        return;
      }
      Object.values(
        (
          err.response as {
            data: {
              message: [];
            };
          }
        ).data.message
      )
        .flat()
        .forEach((text: string) => {
          notify({
            title: "Error",
            text,
            type: "error",
          });
        });
    });
  }
}

export default new ClientOvertimeApiService();
