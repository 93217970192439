import { DownloadFileLinkResponseDto } from '../core';

export class EmployeeDocumentResponseDto {
  uuid?: string;
  createdAt?: Date;
  updatedAt?: Date;
  document?: DownloadFileLinkResponseDto;
  thumbnail?: string;
  expiryDate?: Date;
}
