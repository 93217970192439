import axios, { AxiosError } from "axios";
import { getToken } from "./auth";
import { notify } from "@kyvg/vue3-notification";

export const baseUrl = process.env.VUE_APP_BASE_API_URL;

const service: any = axios.create({ withCredentials: true });
service.interceptors.request.use(
  (request: any) => {
    request.headers["Authorization"] = "Bearer " + getToken();
    return request;
  },
  (err: any) => {
    Promise.reject(err);
  }
);
export function axiosReq({
  url,
  data,
  method,
  baseURL,
  timeout,
  responseType,
}: any): any {
  return service({
    url: url,
    method: method ?? "get",
    data: data ?? {},
    responseType: responseType || "",
    baseURL: baseURL ?? process.env.VUE_APP_BASE_API_URL,
    timeout: timeout ?? 15000,
  }).catch((err: AxiosError) => {
    if (err.response?.status === 409 || err.response?.status === 401) {
      notify({
        title: "Error",
        text: err.response.data.message,
        type: "error",
      });
      return;
    }
    Object.values(
      (
        err.response as {
          data: {
            message: [];
          };
        }
      ).data.message
    )
      .flat()
      .forEach((text: string) => {
        notify({
          title: "Error",
          text,
          type: "error",
        });
      });
  });
}

export default axiosReq;
