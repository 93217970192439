<template>
  <div id="app">
    <notifications position="bottom right">
      <template #body="props">
        <div class="notification" :class="props.item.type">
          <PaperFailSVGComponent />
          <div class="content">
            <p class="title">
              {{ props.item.title }}
            </p>
            <p class="text">{{ props.item.text }}</p>
          </div>
          <PlusSVGComponent class="close" @click="close" color="#fff" />
        </div>
      </template>
    </notifications>
    <ring-loader
      :loading="loading"
      color="#0e3eba"
      class="spinner"
    ></ring-loader>
    <router-view v-if="isUserLogin" />
    <template v-else-if="isLoadingEnd">
      <NavBar />
      <BaseNotifications />
      <div class="content" @scroll="test">
        <router-view />
      </div>
    </template>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, toRaw } from "vue";
import RingLoader from "vue-spinner/src/RingLoader.vue";
import NavBar from "./components/NavBar/NavBar.vue";
import { PlusSVGComponent, PaperFailSVGComponent } from "./assets/svg";
import SignUp from "./views/client/Login/SignUp.vue";
import store from "@/store";
import GoogleAnalyticsService from "@/services/GoogleAnalyticsService";
import BaseNotifications from "./components/Notifications/BaseNotifications.vue";

export default defineComponent({
  name: "App",
  components: {
    NavBar,
    SignUp,
    PlusSVGComponent,
    PaperFailSVGComponent,
    RingLoader,
    BaseNotifications,
  },
  inject: ["Auth"],
  data() {
    return {
      isUserLogin: false,
      isLoadingEnd: false,
    };
  },
  async mounted() {
    const user = toRaw(this.Auth?.user?.value);
    store.commit("setAuthUser", user);

    store.subscribe((mutation, state) => {
      const uuid = state?.auth?.user?.uuid;
      this.isUserLogin = !uuid;
      if (user) {
        const role = user["http://www.auxillium.com/roles"]
          ? user["http://www.auxillium.com/roles"][0]
          : "Error";
        GoogleAnalyticsService.setProperties(uuid, role, user.email);
      }
    });
    this.isUserLogin = !store?.getters?.isAuthenticated;
    this.isLoadingEnd = true;
  },
  setup() {
    const loading = computed(() => {
      return store.getters.isLoading;
    });

    return {
      loading,
    };
  },
});
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css?family=Red+Hat+Display");
@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@800&display=swap");
body {
  margin: 0;
  padding: 0;
}
#app {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  background-color: $base-light-gray;
}

.spinner {
  position: absolute;
  top: calc(50% - 40px);
  right: calc(50% - 40px);
  z-index: 100;
}

.vue-notification-group {
  width: auto !important;
  min-width: 25% !important;
  margin-bottom: 16px;
  margin-right: 16px;
}

.notification {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 22px;
  padding-right: 22px;
  margin: 0 5px 5px;
  box-sizing: border-box;
  border-radius: 8px;
  height: 80px;
  color: $base-white;

  .content {
    height: 100%;
    width: 100%;
    padding-left: 22px;
    padding-right: 22px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .title {
      @include text(16px, 21px, normal);
      margin-bottom: 8px;
    }

    .text {
      @include text(14px, 19px, normal);
    }
  }

  .close {
    position: absolute;
    top: 14px;
    right: 18px;
  }

  &.success {
    background: #68cd86;
    border-left-color: #42a85f;
  }

  &.warn {
    background: #ffb648;
    border-left-color: #f48a06;
  }

  &.error {
    background: $red;
    border-left-color: $red;
  }
}

.content {
  width: calc(100% - 184px);
  overflow-y: auto;
  height: 100vh;
}
</style>
