import { createApp } from "vue";
import Notifications from "@kyvg/vue3-notification";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { Auth0 } from "./auth0";
import VueGtag from "vue-gtag";
import i18n from "./i18n";
import Components from "@auxiliumservices/component";

export const ACTIVE_VALUE = "active";
async function init() {
  const query = new URLSearchParams(location.search);
  const isVerification = query.get("isVerification");
  const isEmailError = query.get("isEmailError");
  const isPasswordReset = query.get("isPasswordReset");

  let redirectUri = window.location.origin;

  if (isVerification === ACTIVE_VALUE) {
    redirectUri = `${window.location.origin}/?step=verification`;
  }

  if (isPasswordReset === ACTIVE_VALUE) {
    redirectUri = `${window.location.origin}/?step=password-reset`;
  }

  if (isEmailError === ACTIVE_VALUE) {
    redirectUri = `${window.location.origin}/?step=email-error`;
  }

  const AuthPlugin = await Auth0.init({
    domain: process.env.VUE_APP_AUTH0_DOMAIN || "",
    clientId: process.env.VUE_APP_AUTH0_CLIENT_ID || "",
    onRedirectCallback: () => {
      window.location.href = "/";
    },
    redirectUri,
  });

  createApp(App)
    .use(AuthPlugin)
    .use(Components)
    .use(store)
    .use(router)
    .use(Notifications)
    .use(i18n)
    .use(VueGtag, {
      config: {
        id: "G-ZCDYTXHR9D",
        params: {
          send_page_view: false,
        },
      },
    })
    .mount("#app");
}

init();
