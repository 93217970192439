import countryList from "./countries-codes-flags.json";

export type CountryCode = {
  flag: "string";
  code: "string";
  country: "string";
  dialCode: "string";
};
class CountryService {
  private countryArray: CountryCode[] = [];
  constructor() {
    this.countryArray = Object.values(countryList) as CountryCode[];
  }

  getCountryList() {
    return this.countryArray;
  }

  getCountryListForSelect() {
    return this.countryArray.map((item: CountryCode) => {
      return {
        value: item.country,
        name: item.country,
        dialCode: item.dialCode,
        flag: item.flag,
        code: item.code,
      };
    });
  }

  getCountryByName(countryName: string) {
    const country = this.countryArray.find(
      (country) => country.country === countryName
    );
    return country
      ? country
      : { flag: "", code: "", country: "", dialCode: "" };
  }

  getCountryByCode(code: string) {
    return this.countryArray.find((country) => country.code === code);
  }
}

export default new CountryService();
