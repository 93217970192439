<template>
  <div class="container">
    <div class="info-line">
      <p class="info text">{{ title }}</p>
      <p class="statistic">{{ stat }}</p>
    </div>
    <div class="line" :class="classLine"></div>
    <Divider />

    <div class="secondary-info" v-if="secondaryInfo && !notApplicable">
      {{ secondaryInfo }}
      <template v-if="isAccured">
        <VerticalDivider class="vertical-divider" />
        <span class="green-font">{{ $t("employee.employee-working-days-accured") }}</span>
      </template>
    </div>
    <div v-if="notApplicable" class="secondary-info red-font">
      {{ $t("employee.employee-not-applicable") }}
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import VerticalDivider from "@/components/Elements/VerticalDivider.vue";

export default defineComponent({
  name: "CardOvertime",
  components: {
    VerticalDivider,
  },
  props: {
    secondaryInfo: {
      type: String,
    },
    notApplicable: {
      type: Boolean,
      required: true,
      default: false
    },
    classLine: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    stat: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: true,
      default: "22%",
    },
    isAccured: {
      type: Boolean,
      required: true,
      default: false
    },
  },
});
</script>

<style lang="scss" scoped>
.container {
  width: 24%;
  height: 101px;
  border: 1px solid rgba(10, 30, 70, 0.09);
  border-radius: 8px;
  padding: 16px;
  box-sizing: border-box;

  min-width: 180px;
}
.text {
  @include text(10px, 13px, 700);
  text-transform: uppercase;
  color: $base-gray;
}

.statistic {
  @include text(10px, 13px, 700);
  text-transform: uppercase;
  color: $base-gray;
  max-width: 50%;
}

.details {
  div {
    margin-top: 32px;
  }
}

.bottom {
  height: 44px;
  display: flex;
  @include text(12px, 16px, 700);
  color: $base-gray;

  .details {
    margin-right: 48px;

    div {
      margin-top: 16px;
    }
  }
}

.info-line {
  display: flex;
  justify-content: space-between;
}

.margin-info {
  margin-bottom: 24px;
}

.first {
  margin-top: 0;
}

.last {
  margin-bottom: 0;
}

.line {
  height: 8px;
  width: 100%;
  background: $light-green;
  border-radius: 30px;
  margin-top: 16px;
  margin-bottom: 16px;
  position: relative;
}

.line:after {
  content: "\A";
  position: absolute;
  background: $green;
  border-radius: 30px;
  top: 0;
  bottom: 0;
  left: 0;
  width: v-bind(width);
}

.green {
  background: $light-green;
}

.green-font {
  color: $light-green;
}

.green:after {
  background: $green;
  width: v-bind(width);
}

.red {
  background: $light-red;
}
.red:after {
  background: $red;
  width: v-bind(width);
}

.yellow {
  background: $light-yellow;
}
.yellow:after {
  background: $yellow;
  width: v-bind(width);
}

.secondary-info {
  height: 16px;
  @include text(12px, 16px, 700);
  color: $base-gray;
  display: flex;
  align-items: center;

  &.red-font {
    color: $red;
  }

  .vertical-divider {
    margin: 0 5px;
  }
}

.red {
  color: $red;
}
</style>
