<template>
  <div class="status-container">
    <div class="status-icon">
      <img :src="icon" alt="icon" />
    </div>
    <p class="status-text">{{ data.text }}</p>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, toRaw, watch } from "vue";
import check from "@/assets/check-circle.svg";
import dots from "@/assets/dots-horizontal.svg";
import dis from "@/assets/x-circle.svg";
import { STATUSES } from "@/services/EmployeeService/constants";

export default defineComponent({
  name: "StatusIcon",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const color = ref("#FFF3DD");
    const icon = ref(dots);
    const iconContainerColor = ref("#FFC456");
    const setData = () => {
      const data = toRaw(props.data);
      if (data.statusType === STATUSES.pending) {
        color.value = "#FFF3DD";
        iconContainerColor.value = "#FFC456";
        icon.value = dots;
      }
      if (data.statusType === STATUSES.active) {
        color.value = "#DFF5ED";
        iconContainerColor.value = "#5FCBA7";
        icon.value = check;
      }
      if (data.statusType === STATUSES.dismissed) {
        iconContainerColor.value = "#FF7C6D";
        color.value = "#FFE5E2";
        icon.value = dis;
      }
    };
    onMounted(() => setData());
    watch(
      () => props.data.statusType,
      () => setData()
    );

    return {
      color,
      iconContainerColor,
      icon,
    };
  },
});
</script>

<style lang="scss" scoped>
.status-container {
  display: flex;
  align-items: center;
  width: 150px;
  height: 24px;
  border-radius: 4px;
  background-color: v-bind(color);

  .status-text {
    @include text(12px, 16px, 500);

    letter-spacing: 0.01em;
    color: $base-gray;
  }

  .status-icon {
    margin-right: 8px;
    background-color: v-bind(iconContainerColor);
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px 0 0 4px;
  }
}
</style>
