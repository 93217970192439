<template>
  <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_4061_7402" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="20">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.000244141 0.0117188H17.0524V19.8652H0.000244141V0.0117188Z" fill="white"/>
    </mask>
    <g mask="url(#mask0_4061_7402)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M4.57331 1.51172C2.91631 1.51172 1.54031 2.85372 1.50131 4.50872V15.2277C1.46531 16.9287 2.80531 18.3287 4.49031 18.3657H12.5733C14.2423 18.3057 15.5633 16.9287 15.5523 15.2327V6.33972L10.9183 1.51172H4.58531H4.57331ZM4.58531 19.8657H4.45931C1.94731 19.8117 -0.0526876 17.7237 0.00131242 15.2117V4.49072C0.0603124 2.00972 2.10831 0.0117188 4.57031 0.0117188H4.58831H11.2373C11.4413 0.0117188 11.6363 0.0947188 11.7783 0.241719L16.8443 5.51872C16.9773 5.65772 17.0523 5.84472 17.0523 6.03772V15.2277C17.0683 17.7367 15.1123 19.7757 12.5993 19.8657H4.58531Z" fill="#0E3EBA"/>
    </g>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2979 6.984H13.5439C11.7129 6.979 10.2259 5.487 10.2259 3.659V0.75C10.2259 0.336 10.5619 0 10.9759 0C11.3899 0 11.7259 0.336 11.7259 0.75V3.659C11.7259 4.663 12.5419 5.481 13.5459 5.484H16.2979C16.7119 5.484 17.0479 5.82 17.0479 6.234C17.0479 6.648 16.7119 6.984 16.2979 6.984" fill="#0E3EBA"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.1427 14.7002C7.7287 14.7002 7.3927 14.3642 7.3927 13.9502V7.90918C7.3927 7.49518 7.7287 7.15918 8.1427 7.15918C8.5567 7.15918 8.8927 7.49518 8.8927 7.90918V13.9502C8.8927 14.3642 8.5567 14.7002 8.1427 14.7002" fill="#0E3EBA"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.14174 14.6985C7.94274 14.6985 7.75074 14.6195 7.61074 14.4775L5.26574 12.1235C4.97374 11.8295 4.97474 11.3545 5.26774 11.0625C5.56174 10.7705 6.03674 10.7705 6.32874 11.0645L8.14174 12.8865L9.95474 11.0645C10.2467 10.7705 10.7217 10.7705 11.0157 11.0625C11.3087 11.3545 11.3097 11.8295 11.0177 12.1235L8.67274 14.4775C8.53274 14.6195 8.34074 14.6985 8.14174 14.6985" fill="#0E3EBA"/>
  </svg>
</template>

<script>
export default {
  name: "DownloadDocumentSVGComponent",
  props: {
    color: {
      type: String,
      required: false,
    },
    className: {
      type: String,
      required: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.disabled {
  path {
    fill: #666f88;
  }
}
</style>