import { validate, ValidationError } from "class-validator";

export default abstract class BaseModel {

    public errors: Object;
    public isFormValid: boolean;

    public get isValid(): boolean {
        return this.isFormValid;
    }

    public async validateModel() {
        try {
            this.errors = {};
            const isModelErrorsExists = (await validate(this)).length;
            if (isModelErrorsExists) {
                let result = await validate(this, { skipMissingProperties: true });
                this.errors = this.setError(result);
                this.isFormValid = false;
            } else {
                this.errors = this.setError([]);
                this.isFormValid = true;
            }
        } catch (err) {
            console.log('validateErr', err)
        }
    }

    private setError(result: ValidationError[]): Object {
        let propBag = {};

        for (const error of result) {
            for (const key in error.constraints) {
                if (Object.prototype.hasOwnProperty.call(error.constraints, key)) {
                    const msg = error.constraints[key];
                    (propBag as any)[error.property] = msg;
                }
            }
        }

        return propBag;
    }
}