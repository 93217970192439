<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Iconly/Light-Outline/Show">
      <g id="Show">
        <path
          id="Fill 1"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.9996 9.64136C10.6696 9.64136 9.58856 10.7234 9.58856 12.0534C9.58856 13.3824 10.6696 14.4634 11.9996 14.4634C13.3296 14.4634 14.4116 13.3824 14.4116 12.0534C14.4116 10.7234 13.3296 9.64136 11.9996 9.64136ZM11.9996 15.9634C9.84256 15.9634 8.08856 14.2094 8.08856 12.0534C8.08856 9.89636 9.84256 8.14136 11.9996 8.14136C14.1566 8.14136 15.9116 9.89636 15.9116 12.0534C15.9116 14.2094 14.1566 15.9634 11.9996 15.9634Z"
          fill="white"
        />
        <g id="Group 5">
          <mask
            id="mask0_1953_45134"
            style="mask-type: alpha"
            maskUnits="userSpaceOnUse"
            x="2"
            y="4"
            width="20"
            height="17"
          >
            <path
              id="Clip 4"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2.00012 4.00024H22V20.1052H2.00012V4.00024Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0_1953_45134)">
            <path
              id="Fill 3"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3.56987 12.0525C5.42987 16.1615 8.56287 18.6045 11.9999 18.6055C15.4369 18.6045 18.5699 16.1615 20.4299 12.0525C18.5699 7.94451 15.4369 5.50151 11.9999 5.50051C8.56387 5.50151 5.42987 7.94451 3.56987 12.0525ZM12.0019 20.1055H11.9979H11.9969C7.86087 20.1025 4.14687 17.2035 2.06087 12.3485C1.97987 12.1595 1.97987 11.9455 2.06087 11.7565C4.14687 6.90251 7.86187 4.00351 11.9969 4.00051C11.9989 3.99951 11.9989 3.99951 11.9999 4.00051C12.0019 3.99951 12.0019 3.99951 12.0029 4.00051C16.1389 4.00351 19.8529 6.90251 21.9389 11.7565C22.0209 11.9455 22.0209 12.1595 21.9389 12.3485C19.8539 17.2035 16.1389 20.1025 12.0029 20.1055H12.0019Z"
              fill="white"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "ShowSVGComponent",
  props: {
    color: {
      type: String,
      required: false,
    },
  },
};
</script>
