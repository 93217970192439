import { getToken } from "@/utils/auth";
import { io } from "socket.io-client";
import { notify } from "@kyvg/vue3-notification";
import { Notification } from "./types";
import {
  DocumentAlreadyExpiredClientNotification,
  DocumentAlreadyExpiredEmployeeNotification,
  DocumentRequestApprovedEmployeeNotification,
  DocumentRequestCreatedNotification,
  DocumentRequestRejectedNotification,
  DocumentRequestSendNotification,
  DocumentWillExpireClientNotification,
  DocumentWillExpireEmployeeNotification,
  WorkExpenseApprovedNotification,
  WorkExpenseCreatedNotification,
  WorkExpenseRejectedNotification,
} from "@/dto/src/notification";
import store from "@/store";
import get from "lodash/get";

const clientNotification = (notification: Notification, type: string) => {
  const clientDocumentNotifications = [
    "DocumentAlreadyExpiredClientNotification",
    "DocumentWillExpireClientNotification",
  ];
  const clientDocumenReqNotifications = ["DocumentRequestCreatedNotification"];
  const clientWorkExpenseNotifications = ["WorkExpenseCreatedNotification"];

  if (clientDocumentNotifications.includes(type)) {
    const notWithType = notification as
      | DocumentAlreadyExpiredClientNotification
      | DocumentWillExpireClientNotification;
    return {
      name: `EmployeesPage`,
      params: {
        userId: notWithType.data.employee.uuid,
        tab: "Documents",
      },
    };
  }

  if (clientDocumenReqNotifications.includes(type)) {
    const notWithType = notification as DocumentRequestCreatedNotification;
    return {
      name: `Documents`,
    };
  }

  if (clientWorkExpenseNotifications.includes(type)) {
    const notWithType = notification as WorkExpenseCreatedNotification;
    return {
      name: `Work-expenses`,
    };
  }
};

const employeeNotification = (notification: Notification, type: string) => {
  const employeeDocumentNotifications = [
    "DocumentAlreadyExpiredEmployeeNotification",
    "DocumentWillExpireEmployeeNotification",
  ];
  const employeeDocumenReqNotifications = [
    "DocumentRequestApprovedEmployeeNotification",
    "DocumentRequestRejectedNotification",
    "DocumentRequestSendNotification",
  ];
  const employeeWorkExpenseNotifications = [
    "WorkExpenseApprovedNotification",
    "WorkExpenseRejectedNotification",
  ];

  if (employeeDocumentNotifications.includes(type)) {
    const notWithType = notification as
      | DocumentAlreadyExpiredEmployeeNotification
      | DocumentWillExpireEmployeeNotification;
    return {
      name: `EmployeesPage`,
      params: {
        userId: notWithType.data.employee.uuid,
        tab: "Documents",
      },
    };
  }

  if (employeeDocumenReqNotifications.includes(type)) {
    const notWithType = notification as
      | DocumentRequestApprovedEmployeeNotification
      | DocumentRequestRejectedNotification
      | DocumentRequestSendNotification;
    return {
      name: `EmployeeDocuments`,
    };
  }

  if (employeeWorkExpenseNotifications.includes(type)) {
    const notWithType = notification as
      | WorkExpenseApprovedNotification
      | WorkExpenseRejectedNotification;
    return {
      name: `EmployeeExpensies`,
    };
  }
};
export const linkGenerator = (notification: Notification) => {
  const type = notification.type;

  return (
    clientNotification(notification, type) ||
    employeeNotification(notification, type)
  );
};

class SocketService {
  s: any;
  constructor() {}

  connect(url: string) {
    this.s = io(url, {
      transports: ["websocket"],
      query: {
        authorization: getToken(),
        behalfontype: get(store, "state.auth.role"),
        behalfonuuid: get(store, "state.auth.user.uuid"),
      },
    });

    return this.s;
  }

  addListener(type: string, fn: Function) {
    this.s.on(type, (params: any) => {
      try {
        fn(params);
      } catch (err) {
        console.log("err", err);
      }
    });
  }

  emit(type: string, data: any) {
    this.s.emit(type, data);
  }

  disconect() {
    this.s.on("disconnect", (s: any) => {
      console.log("disconnect", s);
    });
  }

  showNotification(data: Notification) {
    const link = linkGenerator(data);
    notify({
      id: data.id,
      title: "Notification",
      text: data.text,
      type: "info",
      duration: 10000,
      data: { link },
    });
  }
}

export default new SocketService();
