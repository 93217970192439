import { MinLength, Matches, IsOptional } from 'class-validator';
import { IsDateString } from 'class-validator';

export class UpdateClientRequestDto {
  @MinLength(8, { message: 'Must have 8 characters in length' })
  @Matches(/[!@#$%^&*]+/, { message: 'Must contain one of characters !@#$%^&*' })
  @Matches(/[a-z]+/, { message: 'Must contain at least one lower case character' })
  @Matches(/[A-Z]+/, { message: 'Must contain at least one upper case character' })
  @Matches(/[0-9]+/, { message: 'Must contain at least one number' })
  @IsOptional()
  password: string;

  @IsDateString()
  @IsOptional()
  startDate?: Date;
}
