<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.33341 5.35718C2.41294 5.35718 1.66675 6.10337 1.66675 7.02385V8.40281C1.66675 9.13883 2.14953 9.7877 2.8545 9.99919L9.04226 11.8555C9.66705 12.0429 10.3331 12.0429 10.9579 11.8555L17.1457 9.99919C17.8506 9.7877 18.3334 9.13883 18.3334 8.40281V7.02384C18.3334 6.10337 17.5872 5.35718 16.6667 5.35718H3.33341ZM10.7501 8.21444C10.7501 7.80022 10.4143 7.46444 10.0001 7.46444C9.58587 7.46444 9.25008 7.80022 9.25008 8.21444V9.643C9.25008 10.0572 9.58587 10.393 10.0001 10.393C10.4143 10.393 10.7501 10.0572 10.7501 9.643V8.21444Z"
      :fill="color || '#0A1E46'"
    />
    <path
      opacity="0.3"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.73633 10.8436V14.1664C1.73633 16.3526 3.50854 18.1248 5.69466 18.1248H14.3058C16.4919 18.1248 18.2641 16.3526 18.2641 14.1664V10.8435C18.0335 10.9944 17.7788 11.1143 17.5048 11.1965L11.3171 13.0528C10.458 13.3105 9.54217 13.3105 8.68308 13.0528L2.49532 11.1965C2.22151 11.1143 1.96685 10.9945 1.73633 10.8436ZM1.78767 9.02637L10.0002 10.4166L18.2125 9.02642C18.027 9.48571 17.6412 9.85053 17.1457 9.99921L10.9579 11.8555C10.3331 12.043 9.66705 12.043 9.04226 11.8555L2.8545 9.99921C2.3589 9.85053 1.97311 9.48568 1.78767 9.02637ZM9.99967 11.6665L10.0001 11.6666L10.0001 11.6666L9.99967 11.6665Z"
      :fill="color || '#0A1E46'"
    />
    <path
      d="M12.7777 5.35713V4.5C12.7777 3.39543 11.8823 2.5 10.7777 2.5H9.22217C8.1176 2.5 7.22217 3.39543 7.22217 4.5L7.22217 5.35713"
      :stroke="color || '#0A1E46'"
      stroke-width="1.5"
    />
  </svg>
</template>

<script>
export default {
  name: "CaseSVGComponent",
  props: {
    color: {
      type: String,
      required: false,
    },
  },
};
</script>
