import { PaginationQueryRequestDto } from '../../../core';
import { EmployeeWorkExpenseStatus } from '../../enums';
import { EmployeeWorkExpenseType } from '../../enums';
import { IsDateString, IsEnum, IsOptional, IsString } from 'class-validator';

export class EmployeeWorkExpensePaginationQueryRequestDto extends PaginationQueryRequestDto {
  @IsOptional()
  @IsString()
  client?: string;

  @IsOptional()
  @IsString()
  employee?: string;

  @IsOptional()
  @IsEnum(EmployeeWorkExpenseType, { each: true })
  types?: EmployeeWorkExpenseType | EmployeeWorkExpenseType[];

  @IsOptional()
  @IsEnum(EmployeeWorkExpenseStatus, { each: true })
  statuses?: EmployeeWorkExpenseStatus | EmployeeWorkExpenseStatus[];

  @IsOptional()
  @IsDateString()
  fromDate?: Date;

  @IsOptional()
  @IsDateString()
  toDate?: Date;
}
