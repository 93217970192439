import axiosReq, { baseUrl } from "@/utils/axiosReq";
import {
  addDays,
  capitalizeFirstLetter,
  formatDate,
  roundNumber,
} from "@/utils/data";
import {
  EmployeeRequestDto,
  EmployeeResponseDto,
  EmployeeStatus,
  EmploymentType,
  EmployeeDocumentRequestEmployeeResponseDto,
  EmployeeDocumentRequestClientResponseDto,
  EmployeeDocumentRequestStatus,
  EmployeeBillingMethod,
  EmployeeOvertimeRequestEmployeeResponseDto,
  EmployeeOvertimeRequestStatus,
  EmployeeReportResponseDto,
} from "@/dto/src";
import { AxiosError, AxiosResponse } from "axios";
import {
  DefaultUserSVGComponent,
  DownloadSVGComponent,
} from "../../assets/svg";
import { notify } from "@kyvg/vue3-notification";
import get from "lodash/get";
import { helper } from "@auxiliumservices/component";
import {
  employeeColumnNames,
  employeeFieldsNames,
  OptionType,
  reportColumnNames,
  reportFieldsNames,
  documentRequestFieldsNames,
  documentRequestColumnNames,
  SelectOption,
  STATUSES,
  DocumentTypesOptions,
  documentRequestClientFieldsNames,
  documentRequestClientColumnNames,
  workExpenseColumnNames,
  workExpenseFieldsNames,
  workExpenseClientColumnNames,
  workExpenseClientFieldsNames,
  WorkExpensesOptions,
} from "./constants";
import { EmployeeTypeMapper } from "@/utils/mappers";
import i18n from "@/i18n";
import { Notification } from "../SocketService/types";
import { Component } from "vue";

const mapStatus = (status: string) => {
  if (status === "onboarding") {
    return STATUSES.pending;
  }

  if (!status) {
    return "none";
  }

  if (status === "inactive") {
    return STATUSES.dismissed;
  }

  return status;
};
class EmployeeService {
  constructor() {}
  private createOptionsFromEnum(valueToOptions: OptionType): SelectOption[] {
    return Object.entries(valueToOptions).map(([key, value]) => {
      return {
        value,
        name: value,
      };
    });
  }

  public getEmployeePaymentMethodOptions(): SelectOption[] {
    return [
      {
        name: i18n.global.t("employee.employee-option-title-eor"),
        value: EmploymentType.EMPLOYER_OF_RECORD,
      },
      {
        name: "Payroll (coming soon)",
        value: EmploymentType.PAYROLL,
        disabled: true,
      },
      {
        name: "Contractor (coming soon)",
        value: EmploymentType.CONTRACTOR,
        disabled: true,
      },
    ];
  }

  public addEmployee(employee: EmployeeRequestDto): AxiosResponse {
    return axiosReq({
      url: "/employees",
      method: "post",
      data: employee,
    });
  }

  public addEmployeeDocument(
    uuid: string,
    type: string,
    fileKey: string
  ): AxiosResponse {
    return axiosReq({
      url: `/employees/${uuid}/documents`,
      method: "post",
      data: {
        type,
        file: fileKey,
      },
    });
  }

  public addEmployeeDocumentRequest(
    uuid: string,
    documentRequest: any
  ): AxiosResponse {
    return axiosReq({
      url: `/employees/${uuid}/document-requests`,
      method: "post",
      data: documentRequest,
    }).catch((err: AxiosError) => {
      notify({
        title: "Error load Employees",
        text: err.message,
        type: "error",
      });
    });
  }

  public addEmployeeWorkExpense(
    uuid: string,
    documentRequest: any
  ): AxiosResponse {
    return axiosReq({
      url: `/employees/${uuid}/work-expense`,
      method: "post",
      data: documentRequest,
    }).catch((err: AxiosError) => {
      notify({
        title: "Error load Employees",
        text: err.message,
        type: "error",
      });
    });
  }

  public updateEmployeeWorkExpense(
    uuid: string,
    doc_uuid: string,
    documentRequest: any
  ): AxiosResponse {
    return axiosReq({
      url: `/employees/${uuid}/work-expense/${doc_uuid}`,
      method: "put",
      data: documentRequest,
    }).catch((err: AxiosError) => {
      if (err.response?.status === 409) {
        notify({
          title: "Error create",
          text: err.response.data.message,
          type: "error",
        });
        return;
      }
      Object.values(
        (
          err.response as {
            data: {
              message: [];
            };
          }
        ).data.message
      )
        .flat()
        .forEach((text: string) => {
          notify({
            title: "Error",
            text,
            type: "error",
          });
        });
    });
  }

  public updateEmployeeDocumentRequest(
    uuid: string,
    doc_uuid: string,
    documentRequest: any
  ): AxiosResponse {
    return axiosReq({
      url: `/employees/${uuid}/document-requests/${doc_uuid}`,
      method: "put",
      data: documentRequest,
    }).catch((err: AxiosError) => {
      if (err.response?.status === 409) {
        notify({
          title: "Error create",
          text: err.response.data.message,
          type: "error",
        });
        return;
      }
      Object.values(
        (
          err.response as {
            data: {
              message: [];
            };
          }
        ).data.message
      )
        .flat()
        .forEach((text: string) => {
          notify({
            title: "Error",
            text,
            type: "error",
          });
        });
    });
  }

  public deleteEmployeeWorkExpense(
    uuid: string,
    doc_uuid: string
  ): AxiosResponse {
    return axiosReq({
      url: `/employees/${uuid}/work-expense/${doc_uuid}`,
      method: "delete",
    }).catch((err: AxiosError) => {
      if (err.response?.status === 409) {
        notify({
          title: "Error create",
          text: err.response.data.message,
          type: "error",
        });
        return;
      }
      Object.values(
        (
          err.response as {
            data: {
              message: [];
            };
          }
        ).data.message
      )
        .flat()
        .forEach((text: string) => {
          notify({
            title: "Error",
            text,
            type: "error",
          });
        });
    });
  }

  public deleteEmployeeDocumentRequest(
    uuid: string,
    doc_uuid: string
  ): AxiosResponse {
    return axiosReq({
      url: `/employees/${uuid}/document-requests/${doc_uuid}`,
      method: "delete",
    }).catch((err: AxiosError) => {
      if (err.response?.status === 409) {
        notify({
          title: "Error create",
          text: err.response.data.message,
          type: "error",
        });
        return;
      }
      Object.values(
        (
          err.response as {
            data: {
              message: [];
            };
          }
        ).data.message
      )
        .flat()
        .forEach((text: string) => {
          notify({
            title: "Error",
            text,
            type: "error",
          });
        });
    });
  }

  public approveEmployeeDocumentRequest(
    uuid: string,
    doc_uuid: string,
    type: string,
    data: { comment: string }
  ): AxiosResponse {
    return axiosReq({
      url: `/employees/${uuid}/document-requests/${doc_uuid}/${type}`,
      method: "post",
      data,
    }).catch((err: AxiosError) => {
      if (err.response?.status === 409) {
        notify({
          title: "Error create",
          text: err.response.data.message,
          type: "error",
        });
        return;
      }
      Object.values(
        (
          err.response as {
            data: {
              message: [];
            };
          }
        ).data.message
      )
        .flat()
        .forEach((text: string) => {
          notify({
            title: "Error",
            text,
            type: "error",
          });
        });
    });
  }

  public approveEmployeeWorkExpense(
    uuid: string,
    doc_uuid: string,
    type: string,
    data: { comment: string }
  ): AxiosResponse {
    return axiosReq({
      url: `/employees/${uuid}/work-expense/${doc_uuid}/${type}`,
      method: "post",
      data,
    }).catch((err: AxiosError) => {
      if (err.response?.status === 409) {
        notify({
          title: "Error create",
          text: err.response.data.message,
          type: "error",
        });
        return;
      }
      Object.values(
        (
          err.response as {
            data: {
              message: [];
            };
          }
        ).data.message
      )
        .flat()
        .forEach((text: string) => {
          notify({
            title: "Error",
            text,
            type: "error",
          });
        });
    });
  }

  public getAllEmployees(
    page: number,
    searchValue = "",
    limit = 15,
    additionalQuery = "",
    cutVersion = false
  ): AxiosResponse {
    const query = searchValue
      ? `&search=${searchValue}${additionalQuery}`
      : additionalQuery;
    return axiosReq({
      url: `/employees?page=${page}&limit=${limit}${query}&cutVersion=${cutVersion}`,
      method: "get",
    }).catch((err: AxiosError) => {
      notify({
        title: "Error load Employees",
        text: err.message,
        type: "error",
      });
    });
  }

  public getAllEmployeesReports(
    page: number,
    clientId: string,
    limit = 15
  ): AxiosResponse {
    return axiosReq({
      url: `/clients/employees/reports?page=${page}&limit=${limit}&client=${clientId}`,
      method: "get",
    }).catch((err: AxiosError) => {
      notify({
        title: "Error load Employees",
        text: err.message,
        type: "error",
      });
    });
  }

  public getSteps(uuid: string): AxiosResponse {
    return axiosReq({
      url: `/employees/${uuid}/employee-steps`,
      method: "get",
    }).catch((err: AxiosError) => {
      notify({
        title: "Error load Employees",
        text: err.message,
        type: "error",
      });
    });
  }

  public getCountrySteps(): AxiosResponse {
    return axiosReq({
      url: `/countries/employee-steps`,
      method: "get",
    }).catch((err: AxiosError) => {
      notify({
        title: "Error load Employees",
        text: err.message,
        type: "error",
      });
    });
  }

  public getEmployeeById(id: string): AxiosResponse {
    return axiosReq({
      url: "/employees/" + id,
      method: "get",
    }).catch((err: AxiosError) => {
      notify({
        title: "Error load Employees",
        text: err.message,
        type: "error",
      });
    });
  }

  public getAllDocumentRequest(page = 1): AxiosResponse {
    return axiosReq({
      url: `/employees/document-requests?page=${page}&limit=15`,
      method: "get",
    }).catch((err: AxiosError) => {
      notify({
        title: "Error load Employees",
        text: err.message,
        type: "error",
      });
    });
  }

  public getAllEmployeeExpenses(page = 1): AxiosResponse {
    return axiosReq({
      url: `/employees/work-expenses?page=${page}&limit=15`,
      method: "get",
    }).catch((err: AxiosError) => {
      notify({
        title: "Error load Employees",
        text: err.message,
        type: "error",
      });
    });
  }

  public getEmployeeReportsExport(page = 1): AxiosResponse {
    return axiosReq({
      url: `/clients/employees/reports/export`,
      method: "get",
      responseType: "blob",
    }).catch((err: AxiosError) => {
      notify({
        title: "Error load Employees",
        text: err.message,
        type: "error",
      });
    });
  }

  public getAllDocumentRequestForClient(
    page = 1,
    employeeId?: string,
    status?: string[],
    isEmployee?: boolean
  ): AxiosResponse {
    let query = "";
    if (employeeId) query += `employee=${employeeId}`;
    if (status) query += (employeeId ? "&" : "") + `statuses=${status}`;
    const path = isEmployee ? "employees" : "clients/employees";
    return axiosReq({
      url: `/${path}/document-requests?page=${page}&limit=15&${
        query ? `${query}` : ""
      }`,
      method: "get",
    }).catch((err: AxiosError) => {
      notify({
        title: "Error load Employees",
        text: err.message,
        type: "error",
      });
    });
  }

  public getDocumentsByCountryAndOnboardingType(
    uuid: string,
    type: string
  ): AxiosResponse {
    return axiosReq({
      url: `/countries/${uuid}/onboarding/${type}/employee-document-types`,
      method: "get",
    });
  }

  public getAllExpensiesForClient(
    page = 1,
    employeeId?: string,
    status?: string[]
  ): AxiosResponse {
    let query = "";
    if (employeeId) query += `employee=${employeeId}`;
    if (status) query += (employeeId ? "&" : "") + `statuses=${status}`;
    return axiosReq({
      url: `/clients/employees/work-expenses?page=${page}&limit=15&${
        query ? `${query}` : ""
      }`,
      method: "get",
    }).catch((err: AxiosError) => {
      notify({
        title: "Error load Employees",
        text: err.message,
        type: "error",
      });
    });
  }

  public getUserStatus(currentStatus: EmployeeStatus, text?: string) {
    if (currentStatus === null) {
      return {
        type: "none",
        statusText: i18n.global.t("employee.employee-option-title-none"),
      };
    }

    if (currentStatus === EmployeeStatus.ACTIVE) {
      return {
        type: STATUSES.active,
        statusText: "Active",
      };
    }

    if (currentStatus === EmployeeStatus.INACTIVE) {
      return {
        type: STATUSES.dismissed,
        statusText: "Inactive",
      };
    }

    return {
      type: STATUSES.pending,
      statusText: `Onboarding ${text}`,
    };
  }

  public getTotalMonthlyCOR(employee: EmployeeResponseDto) {
    const data = helper.getUserTotalMonthlyCOR(employee);

    return data;
  }

  public getEmployeeSalaryInTableFormat(employee: EmployeeResponseDto) {
    const columnNames = [
      {
        name: "MONTHLY COSTS",
        width: "228",
        border: "none",
      },
      {
        name: "",
        border: "none",
      },
    ];
    const fieldsNames = ["title", "sum"];
    const val = this.getTotalMonthlyCOR(employee);
    const currency = get(employee, "employeeSalary.currency.name");
    const salaryType = get(employee, "employeeSalary.salaryType.name");
    const tableData = [
      {
        title: {
          value: i18n.global.t("employee.employee-basic-salary-basicSalary"),
          type: "coloredText",
          font: 800,
        },
        sum: {
          value: `${currency || "None"} ${roundNumber(
            get(employee, "employeeSalary.basicSalary")
          )} / ${salaryType || "None"}`,
          type: "coloredText",
          font: 800,
          size: 14,
        },
      },
      {
        title: i18n.global.t(
          "employee.employee-school-allowance-schoolAllowance"
        ),
        sum: `${currency || "None"} ${roundNumber(
          get(employee, "employeeSalary.schoolAllowance")
        )} / ${salaryType || "None"}`,
      },
      {
        title: i18n.global.t(
          "employee.employee-housing-allowance-housingAllowance"
        ),
        sum: `${currency || "None"} ${roundNumber(
          get(employee, "employeeSalary.housingAllowance")
        )} / ${salaryType || "None"}`,
      },
      {
        title: i18n.global.t(
          "employee.employee-transport-allowance-transportAllowance"
        ),
        sum: `${currency || "None"} ${roundNumber(
          get(employee, "employeeSalary.transportAllowance")
        )} / ${salaryType || "None"}`,
      },
      {
        title: i18n.global.t(
          "employee.employee-other-allowance-1-otherAllowance1"
        ),
        sum: `${currency || "None"} ${roundNumber(
          get(employee, "employeeSalary.otherAllowance1")
        )} / ${salaryType || "None"}`,
      },
      {
        title: i18n.global.t(
          "employee.employee-other-allowance-2-otherAllowance2"
        ),
        sum: `${currency || "None"} ${roundNumber(
          get(employee, "employeeSalary.otherAllowance2")
        )} / ${salaryType || "None"}`,
      },

      {
        title: {
          value: i18n.global.t("employee.employee-gross-salary"),
          type: "coloredText",
          font: 800,
        },
        sum: {
          value: `${currency || "None"} ${val.grossSalary} / ${
            salaryType || "None"
          }`,
          type: "coloredText",
          font: 800,
          size: 14,
        },
      },
    ];

    return {
      columnNames,
      fieldsNames,
      tableData,
    };
  }

  public getEmployeeVisaInTableFormat(employee: EmployeeResponseDto) {
    const columnNames: never[] = [];
    const fieldsNames = ["title", "sum"];
    const val = this.getTotalMonthlyCOR(employee);
    const currency = get(employee, "employeeSalary.currency.name");
    const salaryType = get(employee, "employeeSalary.salaryType.name");
    const tableData = [
      get(employee, "employeeVisa.visaBillingMethod") !== "Upfront" && {
        title: "Visa Recovery",
        sum: `${currency || "None"} ${val.MonthlyVisaRecoveryAmount || 0} / ${
          salaryType || "None"
        }`,
      },
      get(employee, "employeeInsurance.insuranceBillingMethod") ==
        EmployeeBillingMethod.MONTHLY && {
        title: "Insurance Recovery",
        sum: `${currency || "None"} ${
          val.MonthlyInsuranceRecoveryAmount || 0
        } / ${salaryType || "None"}`,
      },
      {
        title: "Leave Salary Accrual",
        sum: `${currency || "None"} ${
          val.MonthlyLeaveSalaryincludedinpricing || 0
        } / ${salaryType || "None"}`,
      },
      {
        title: "Gratuity Accrual",
        sum: `${currency || "None"} ${val.MonthlyGratuityCost || 0} / ${
          salaryType || "None"
        }`,
      },
      {
        title: "Airfare Accrual",
        sum: `${currency || "None"} ${val.MonthlyAirfareAllowance || 0} / ${
          salaryType || "None"
        }`,
      },
      {
        title: "Auxilium Service Fee",
        sum: `${currency || "None"} ${val.totalFee || 0} / ${
          salaryType || "None"
        }`,
      },
      {
        title: {
          value: "Total Monthly COR",
          type: "coloredText",
          font: 800,
        },
        sum: {
          value: `${currency || "None"} ${val.totalCor} / ${
            salaryType || "None"
          }`,
          type: "coloredText",
          font: 800,
          size: 14,
        },
      },
    ];

    return {
      columnNames,
      fieldsNames,
      tableData: tableData.filter((t) => !!t),
    };
  }

  public getEmployeeInvoiceInTableFormat(
    employee: EmployeeResponseDto,
    convertRate: number
  ) {
    const columnNames: never[] = [];
    const fieldsNames = ["title", "sum"];
    const val = this.getTotalMonthlyCOR(employee);
    const currency = get(employee, "employeeSalary.currency.name");
    const salaryType = get(employee, "employeeSalary.salaryType.name");
    const totalMonthly =
      (+get(employee, "employeeOther.VATAmount") || 0) + val.totalCor;
    const invoiceCurrency =
      get(employee, "employeeOther.invoicingCurrency.name") || "";
    const totalMonthlyInvoice = totalMonthly * convertRate;
    const tableData = [
      {
        title: "VAT amount",
        sum: `${currency || "None"} ${
          get(employee, "employeeOther.VATAmount") || 0
        } / ${salaryType || "None"}`,
      },
      {
        title: {
          value: "Total Monthly Invoice",
          type: "coloredText",
          font: 800,
        },
        sum: {
          value: `${invoiceCurrency || "None"} ${roundNumber(
            totalMonthlyInvoice
          )} (${currency || "None"} ${totalMonthly || "None"}) / ${
            salaryType || "None"
          }`,
          type: "coloredText",
          font: 800,
          size: 14,
        },
      },
    ];

    return {
      columnNames,
      fieldsNames,
      tableData,
    };
  }

  public getImgLink(photo: string = "") {
    return `${baseUrl}/employees/documents/${encodeURIComponent(photo || "")}`;
  }

  public getEmployeesInTableFormat(employees: EmployeeResponseDto[]) {
    const getStatusText = (
      status: any,
      currentProgress: number,
      maxProgress: number
    ) => {
      if (status === "onboarding") {
        return `Onboarding ${currentProgress}/${maxProgress}`;
      }
      return capitalizeFirstLetter(status || "Document Collection");
    };

    const data = employees.map((employee) => {
      const status = {
        statusText: getStatusText(
          employee.status,
          (employee as any).onboardingStatus?.currentProgress,
          (employee as any).onboardingStatus?.maxProgress
        ),
        type: mapStatus(employee.status as string),
      };
      return {
        id: employee.uuid,
        employeeId: employee.employeeId ? employee.employeeId : "",
        fullName: {
          value: employee.fullName,
          img: employee.photo
            ? (employee as any).photo.downloadLink
            : DefaultUserSVGComponent,
          isEmptyImg: !!employee.photo,
          type: "name",
        },
        position: employee.position,
        startDate: formatDate(employee.startDate),
        contractTenure:
          employee.contractTenure === null ? "None" : employee.contractTenure,
        country: get(employee, "country.name" || "None"),
        employmentType:
          EmployeeTypeMapper[get(employee, "employmentType" || "None")],
        status: {
          type: "customComponent",
          component: "status",
          statusType: status.type,
          text: status.statusText,
        },
      };
    });
    return {
      columnNames: employeeColumnNames,
      fieldsNames: employeeFieldsNames,
      tableData: data,
    };
  }

  public getReportsInTableFormat(employees: EmployeeReportResponseDto[]) {
    const getStatusText = (diff: number, onboardingDueDate: Date) => {
      if (!onboardingDueDate) {
        return "Empty Date";
      }
      return diff < 0 ? `${Math.abs(diff)} days behind` : "On Time";
    };
    const tableData = employees.map((employee) => {
      const status = {
        statusText: getStatusText(
          employee.onboardingStatus?.onboardingOnTimeDays as number,
          employee.onboardingStatus?.onboardingDueDate as Date
        ),
        type:
          (employee.onboardingStatus?.onboardingOnTimeDays as number) < 0
            ? STATUSES.dismissed
            : STATUSES.active,
      };
      return {
        id: employee.uuid,
        name: {
          value: employee.fullName,
          img: employee.photo
            ? (employee as any).photo.downloadLink
            : DefaultUserSVGComponent,
          isEmptyImg: !!employee.photo,
          type: "name",
        },
        position: employee.position,
        operation_country: employee.countryName,
        progress: {
          type: "customComponent",
          component: "progress",
          text: `${employee.onboardingStatus?.currentProgress} / ${employee.onboardingStatus?.maxProgress}`,
          progress:
            ((employee.onboardingStatus?.currentProgress || 0) /
              (employee.onboardingStatus?.maxProgress || 1)) *
              100 +
            "%",
          class:
            employee.onboardingStatus?.onboardingOnTimeDays || 0 < 0
              ? STATUSES.dismissed
              : STATUSES.active,
        },
        start_date: formatDate(employee.onboardingStatus?.onboardingDueDate),
        status: {
          type: "customComponent",
          component: "status",
          statusType: status.type,
          text:
            employee.onboardingStatus?.employeeStatus === null
              ? "Document Collection"
              : status.statusText,
        },
        onboardingNotes: {
          type: "customComponent",
          component: "comment",
          text: employee.onboardingNotes ? employee.onboardingNotes : "",
        },
      };
    });

    return {
      columnNames: reportColumnNames,
      fieldsNames: reportFieldsNames,
      tableData,
    };
  }

  public getOvertimeAndLeavesInTableFormat(employees: EmployeeResponseDto[]) {
    const columnNames = [
      {
        name: "Employee",
        width: "228",
      },
      {
        name: "Requested On",
        width: "132",
      },
      {
        name: "Overtime Type",
        width: "136",
      },
      {
        name: "Overtime Date",
        width: "190",
      },
      {
        name: "Hours",
        width: "133",
      },
      {
        name: "Comment",
        width: "163",
      },
      {
        name: "Status",
        width: "162",
      },
      {
        name: "Actions",
        width: "162",
      },
    ];

    const fieldsNames = [
      "name",
      "position",
      "date",
      "operation_country",
      "salary",
      "type",
      "status",
      "action",
    ];
    const data = employees.map((employee) => {
      const status = this.getUserStatus(employee.status as EmployeeStatus);
      return {
        id: employee.uuid,
        name: {
          value: employee.fullName,
          img: employee.photo
            ? employee.photo.downloadLink
            : DefaultUserSVGComponent,
          isEmptyImg: !!employee.photo,
          type: "name",
        },
        position: employee.position,
        date: formatDate(employee.startDate),
        operation_country: get(employee, "country.name" || "None"),

        salary: `${get(employee, "employeeSalary.currency.name") || "None"} ${
          get(employee, "employeeSalary.basicSalary") || "None"
        }/${get(employee, "employeeSalary.salaryType.name") || "None"}`,
        type: {
          type: "customComponent",
          component: "comment",
          text: status.statusText,
        },
        status: {
          type: "customComponent",
          component: "status",
          statusType: status.type,
          text: status.statusText,
        },
        action: {
          type: "customComponent",
          component: "approveAction",
          statusType: status.type,
          text: status.statusText,
        },
      };
    });
    return {
      columnNames,
      fieldsNames,
      tableData: data,
    };
  }

  public getDocumentsRequestsInTableFormat(
    documentRequests: EmployeeDocumentRequestClientResponseDto[]
  ) {
    const getTypeOption = (type: string) => {
      return DocumentTypesOptions.find((docType) => docType.value === type);
    };
    const data = documentRequests.map((documentRequest) => {
      let labelText = documentRequest.status as string;

      if (documentRequest.status === EmployeeDocumentRequestStatus.PENDING) {
        labelText = i18n.global.t("elements.elements-requested-text");
      }

      if (documentRequest.status === EmployeeDocumentRequestStatus.APPROVED) {
        labelText = i18n.global.t("elements.elements-issued-text");
      }
      return {
        id: documentRequest.uuid,
        name: {
          value: documentRequest.employee.fullName,
          img: documentRequest.employee.photo
            ? documentRequest.employee.photo.downloadLink
            : DefaultUserSVGComponent,
          isEmptyImg: !!documentRequest.employee.photo,
          type: "name",
        },
        employeeId: documentRequest.employee.uuid,
        employeeCustomId: documentRequest.employee.employeeId,
        type: getTypeOption(documentRequest.type)?.name,
        createdAt: formatDate(documentRequest.createdAt),
        issuedOn: documentRequest.issuedOn
          ? formatDate(documentRequest.issuedOn)
          : "",
        comment: {
          type: "customComponent",
          component: "comment",
          text: documentRequest.comment,
        },
        status: {
          type: "customComponent",
          component: "statusWithComment",
          statusType: documentRequest.status,
          labelText,
          text: documentRequest.clientComment || "",
          awaitingAfterApproval: true,
        },
      };
    });
    return {
      columnNames: documentRequestClientColumnNames,
      fieldsNames: documentRequestClientFieldsNames,
      tableData: data,
    };
  }

  public getDocumentsRequestsForEmployeeInTableFormat(
    documentRequests: EmployeeDocumentRequestClientResponseDto[]
  ) {
    const getTypeOption = (type: string) => {
      return DocumentTypesOptions.find((docType) => docType.value === type);
    };
    const data = documentRequests.map((documentRequest) => {
      return {
        id: documentRequest.uuid,
        document: documentRequest.document,
        type: getTypeOption(documentRequest.type)?.name,
        createdAt: formatDate(documentRequest.createdAt),
        action: {
          type: "customComponent",
          component: "approveAction",
          actionTypes: ["download"],
        },
      };
    });
    return {
      columnNames: [
        {
          name: "Document Type",
          width: "290",
        },
        {
          name: "Requested On",
          width: "135",
        },
        {
          name: "Actions",
          width: "50",
        },
      ],
      fieldsNames: ["type", "createdAt", "action"],
      tableData: data,
    };
  }

  public getWorkExpenseRequestsInTableFormat(documentRequests: any[]) {
    const getTypeOption = (type: string) => {
      return WorkExpensesOptions.find((docType) => docType.value === type);
    };
    const data = documentRequests.map((documentRequest) => {
      return {
        id: documentRequest.uuid,
        type: getTypeOption(documentRequest.type)?.name,
        fileProof: documentRequest.fileProof,
        createdAt: formatDate(documentRequest.createdAt),
        currency: documentRequest.currency.name,
        amount: documentRequest.amount,
        displayAmount: `${documentRequest.currency.name} ${documentRequest.amount}`,
        comment: {
          type: "customComponent",
          component: "comment",
          text: documentRequest.comment,
        },
        status: {
          type: "customComponent",
          component: "statusWithComment",
          statusType: documentRequest.status,
          statusText: documentRequest.status,
          text: documentRequest.clientComment || "",
        },
        action: {
          type: "customComponent",
          component: "approveAction",
          actionTypes: ["edit", "remove"],
          isDisabled:
            documentRequest.status !== EmployeeDocumentRequestStatus.PENDING,
        },
      };
    });
    return {
      columnNames: workExpenseColumnNames,
      fieldsNames: workExpenseFieldsNames,
      tableData: data,
    };
  }

  public getClientWorkExpenseInTableFormat(documentRequests: any[]) {
    const getTypeOption = (type: string) => {
      return WorkExpensesOptions.find((docType) => docType.value === type);
    };
    const data = documentRequests.map((documentRequest) => {
      return {
        id: documentRequest.uuid,
        name: {
          value: documentRequest.employee.fullName,
          img: documentRequest.employee.photo
            ? documentRequest.employee.photo.downloadLink
            : DefaultUserSVGComponent,
          isEmptyImg: !!documentRequest.employee.photo,
          type: "name",
        },
        employeeId: documentRequest.employee.uuid,
        amount: `${documentRequest.currency.name} ${documentRequest.amount}`,
        type: getTypeOption(documentRequest.type)?.name,
        createdAt: formatDate(documentRequest.createdAt),
        comment: {
          type: "customComponent",
          component: "comment",
          text: documentRequest.comment,
        },
        document: documentRequest.fileProof,
        doc: {
          type: "customComponent",
          component: "iconButton",
          comp: DownloadSVGComponent,
          emitText: "download-doc",
          isDisabled: !documentRequest.fileProof,
        },
        status: {
          type: "customComponent",
          component: "statusWithComment",
          statusType: documentRequest.status,
          text: documentRequest.clientComment || "",
        },
        action: {
          type: "customComponent",
          component: "approveAction",
          actionTypes: ["approve", "decline"],
          isDisabled:
            documentRequest.status !== EmployeeDocumentRequestStatus.PENDING,
        },
      };
    });
    return {
      columnNames: workExpenseClientColumnNames,
      fieldsNames: workExpenseClientFieldsNames,
      tableData: data,
    };
  }
  public getEmployeeDocumentsRequestsInTableFormat(
    documentRequests: EmployeeDocumentRequestEmployeeResponseDto[]
  ) {
    const getTypeOption = (type: string) => {
      return DocumentTypesOptions.find((docType) => docType.value === type);
    };
    const data = documentRequests.map((documentRequest) => {
      return {
        id: documentRequest.uuid,
        type: getTypeOption(documentRequest.type)?.name,
        document: documentRequest.document,
        createdAt: formatDate(documentRequest.createdAt),
        comment: {
          type: "customComponent",
          component: "comment",
          text: documentRequest.comment,
        },
        status: {
          type: "customComponent",
          component: "statusWithComment",
          statusType: documentRequest.status,
          statusText: documentRequest.status,
          text: documentRequest.clientComment || "",
          awaitingAfterApproval: true,
        },
        action: {
          type: "customComponent",
          component: "approveAction",
          actionTypes: ["edit", "remove", "download"],
          isDisabled:
            documentRequest.status !== EmployeeDocumentRequestStatus.PENDING,
          isDownloadDisabled:
            documentRequest.status !== EmployeeDocumentRequestStatus.SENT,
        },
      };
    });
    return {
      columnNames: documentRequestColumnNames,
      fieldsNames: documentRequestFieldsNames,
      tableData: data,
    };
  }

  public getClientManagersInTableFormat(
    clientManagers: { uuid: string; fullName: string; email: string }[],
    actions: Component
  ) {
    const columnNames = [
      {
        name: "Name",
        width: "228",
      },
      {
        name: "Email",
        width: "228",
      },
      {
        name: "Actions",
        width: "162",
      },
    ];

    const fieldsNames = ["fullName", "email", "action"];
    const data = clientManagers.map((clientManager) => {
      return {
        id: clientManager.uuid,
        fullName: clientManager.fullName,
        email: clientManager.email,
        action: {
          type: "customComponent",
          dynamicComponent: actions,
        },
      };
    });
    return {
      columnNames,
      fieldsNames,
      tableData: data,
    };
  }

  public getNotificationsInTableFormat(notifications: Notification[]) {
    const columnNames = [
      {
        name: "Text",
        width: "228",
      },
      {
        name: "Date",
        width: "162",
      },
      {
        name: "Actions",
        width: "162",
      },
    ];

    const fieldsNames = ["text", "date", "action"];
    const data = notifications.map((notification) => {
      return {
        id: notification.uuid,
        data: notification,
        date: formatDate(notification.createdAt),
        text: {
          type: "customComponent",
          component: "link",
          text: notification.text,
          emitText: "navigate",
        },
        action: notification.resolvedOn
          ? `Resolved by ${notification.resolvedByUser?.fullName}`
          : {
              type: "customComponent",
              component: "button",
              text: "Resolve",
              emitText: "resolve",
            },
      };
    });
    return {
      columnNames,
      fieldsNames,
      tableData: data,
    };
  }
}

export default new EmployeeService();
