import {
  capitalizeFirstLetter,
  formatDate,
} from "@/utils/data";
import {
  EmployeeOvertimeRequestStatus,
  EmployeeOvertimeRequestClientResponseDto,
} from "@/dto/src";

import i18n from "@/i18n";
import { DefaultUserSVGComponent } from "@/assets/svg";

class ClientOvertimeFormatService {
  constructor() {}

  public getClientOvertimesInTableFormat(
    overtimes: EmployeeOvertimeRequestClientResponseDto[]
  ) {
    const columnNames = [
      {
        name: i18n.global.t('employee.employee-employee'),
        width: "228",
      },
      {
        name: i18n.global.t('elements.elements-requested-on-text'),
        width: "228",
      },
      {
        name: i18n.global.t('elements.elements-overtime-type-text'),
        width: "228",
      },
      {
        name: i18n.global.t('elements.elements-overtime-date-text'),
        width: "228",
      },
      {
        name: i18n.global.t('elements.elements-hours-text'),
        width: "228",
      },
      {
        name: i18n.global.t('elements.elements-comment-text'),
        width: "70",
      },
      {
        name: i18n.global.t('elements.elements-status-text'),
        width: "228",
      },
      {
        name: i18n.global.t('elements.elements-actions-text'),
        width: "162",
      },
    ];

    const fieldsNames = [
      "name",
      "updatedAt",
      "type",
      "forematedDate",
      "amount",
      "comment",
      "status",
      "action",
    ];
    const data = overtimes.map((overtime) => {
      return {
        id: overtime.uuid,
        employeeId: overtime.employee.uuid,
        name: {
          value: overtime.employee.fullName,
          img: overtime.employee.photo
            ? overtime.employee.photo.downloadLink
            : DefaultUserSVGComponent,
          isEmptyImg: !!overtime.employee.photo,
          type: "name",
        },
        updatedAt: formatDate(overtime.updatedAt),
        type: overtime.type || "",
        forematedDate: formatDate(overtime.date || ""),
        date: overtime.date || "",
        amount: overtime.amount || "",
        comment: {
          type: "customComponent",
          component: "comment",
          text: overtime.comment ? overtime.comment : "",
        },
        status: {
          type: "customComponent",
          component: "statusWithComment",
          statusType: overtime.status,
          statusText: capitalizeFirstLetter(overtime.status),
          text: overtime.clientComment || "",
        },
        action: {
          type: "customComponent",
          component: "approveAction",
          actionTypes: ["approve", "decline"],
          isDisabled: overtime.status !== EmployeeOvertimeRequestStatus.PENDING,
        },
      };
    });
    return {
      columnNames,
      fieldsNames,
      tableData: data,
    };
  }
}

export default new ClientOvertimeFormatService();
