import { capitalizeFirstLetter, formatDate } from "@/utils/data";
import {
  EmployeeLeaveRequestStatus,
  EmployeeLeaveRequestClientResponseDto,
  EmployeeLeaveRequestType,
  EmployeeGenders,
} from "@/dto/src";
import { DefaultUserSVGComponent, DownloadSVGComponent } from "@/assets/svg";
import { LeaveTypesOptions } from "@/services/EmployeeService/constants";
import i18n from "@/i18n";

class ClientLeaveFormatService {
  constructor() {}

  private clientLeaveColumnNames = [
    {
      name: i18n.global.t("employee.employee-employee"),
      width: "250",
    },
    {
      name: i18n.global.t("elements.elements-requested-on-text"),
      width: "100",
    },
    {
      name: i18n.global.t("elements.elements-leave-type-text"),
      width: "200",
    },
    {
      name: i18n.global.t("elements.elements-from-text"),
      width: "100",
    },
    {
      name: i18n.global.t("elements.elements-to-text"),
      width: "100",
    },
    {
      name: i18n.global.t("elements.elements-days-text"),
      width: "70",
    },
    {
      name: i18n.global.t("elements.elements-spent-text"),
      width: "70",
    },
    {
      name: i18n.global.t("elements.elements-doc-text"),
      width: "70",
    },
    {
      name: i18n.global.t("elements.elements-comment-text"),
      width: "70",
    },
    {
      name: i18n.global.t("elements.elements-status-text"),
      width: "170",
    },
    {
      name: i18n.global.t("elements.elements-actions-text"),
      width: "100",
    },
  ];

  private clientLeaveFieldNames = [
    "name",
    "updatedAt",
    "type",
    "formattedFrom",
    "formattedTo",
    "businessDays",
    "spentDays",
    "doc",
    "comment",
    "status",
    "action",
  ];

  private getLeaveTypeName(type: string, gender: EmployeeGenders): string {
    return (
      LeaveTypesOptions(gender).find((leaveType) => leaveType.value === type)
        ?.name || ""
    );
  }

  public getClientLeavesInTableFormat(
    leaves: EmployeeLeaveRequestClientResponseDto[]
  ) {
    const data = leaves.map((leave) => {
      return {
        id: leave.uuid,
        employeeId: leave.employee.uuid,
        employee: leave.employee,
        name: {
          value: leave.employee.fullName,
          img: leave.employee.photo
            ? leave.employee.photo.downloadLink
            : DefaultUserSVGComponent,
          isEmptyImg: !!leave.employee.photo,
          type: "name",
        },
        updatedAt: formatDate(leave.updatedAt),
        type: this.getLeaveTypeName(
          leave.type,
          leave.employeeGender || ("male" as EmployeeGenders)
        ),
        leaveType: leave.type,
        formattedFrom: formatDate(leave.from || ""),
        formattedTo: formatDate(leave.to || ""),
        from: leave.from || "",
        to: leave.to || "",
        businessDays: leave.daysCount,
        spentDays:
          leave.employee && leave.type === EmployeeLeaveRequestType.ANNUAL
            ? `${leave.employee.requestedAnnualLeaveCount} of ${leave.employee.totalAnnualLeaveCount}`
            : "-",
        certificate: leave.certificate,
        doc: {
          type: "customComponent",
          component: "iconButton",
          comp: DownloadSVGComponent,
          emitText: "download-doc",
          isDisabled: !leave.certificate,
        },
        comment: {
          type: "customComponent",
          component: "comment",
          text: leave.comment ? leave.comment : "",
        },
        status: {
          type: "customComponent",
          component: "statusWithComment",
          statusType: leave.status,
          statusText: capitalizeFirstLetter(leave.status),
          text: leave.clientComment || "",
          awaitingAfterApproval: true,
        },
        action: {
          type: "customComponent",
          component: "approveAction",
          actionTypes: ["approve", "decline"],
          isDisabled: leave.status !== EmployeeLeaveRequestStatus.PENDING,
        },
      };
    });
    return {
      columnNames: this.clientLeaveColumnNames,
      fieldsNames: this.clientLeaveFieldNames,
      tableData: data,
    };
  }
}

export default new ClientLeaveFormatService();
