import { IsEnum, IsOptional } from 'class-validator';
import { EmployeePaymentMethod } from './enums';

export class EmployeeInfoDto {
  @IsOptional()
  @IsEnum(EmployeePaymentMethod)
  paymentMethod?: EmployeePaymentMethod;

  @IsOptional()
  passportNumber?: string;

  @IsOptional()
  passportIssued?: string;

  @IsOptional()
  passwordExpiry?: string;

  @IsOptional()
  dateOfBirth?: Date;

  @IsOptional()
  dateOfArrival?: Date;

  @IsOptional()
  recruitmentSource?: string;

  @IsOptional()
  entryPermitNumber?: string;

  @IsOptional()
  uidNumber?: string;

  @IsOptional()
  visaIssueDate?: Date;

  @IsOptional()
  residenceVisaIssued?: Date;

  @IsOptional()
  residenceVisaExpire?: Date;

  @IsOptional()
  emiratesIdNumber?: string;

  @IsOptional()
  healthInsuranceExpiry?: Date;

  @IsOptional()
  wmcExpiry?: Date;

  @IsOptional()
  employeeCategory?: string;

  @IsOptional()
  bank?: string;

  @IsOptional()
  accountNumber?: string;

  @IsOptional()
  cardSerialNumber?: string;

  @IsOptional()
  locationContractNumber?: string;

  @IsOptional()
  secondBank?: string;

  @IsOptional()
  ppCustody?: string;

  @IsOptional()
  reasonForLeaving?: string;

  @IsOptional()
  lastPeriodDay?: Date;

  @IsOptional()
  deportedDate?: Date;

  @IsOptional()
  remarks?: string;
}
